import React, { useEffect, useState } from "react";
import { MdClose } from "react-icons/md";
import { useEmployeeContext } from "../../../../context";
import ReactQuill from "react-quill";
import { TagsInput } from "react-tag-input-component";

const BasicDetailUpdateModal = ({ handleModalClose, currentBasicDetail }) => {
    const { updateProfileInfoDetails } = useEmployeeContext();
    const user = JSON.parse(sessionStorage.getItem("data"));
    const [loading, setLoading] = useState(false);
    const [selected, setSelected] = useState([]);
    const [tags, setTags] = useState([]);
    const [formValues, setFormValues] = useState({
        userId: user._id,
        bio: currentBasicDetail?.bio || "",
        skills: currentBasicDetail?.skills || [],
        description: currentBasicDetail?.description || "",
        dob: currentBasicDetail?.dob || "",
        links: currentBasicDetail?.links || [],
        isActive: currentBasicDetail?.isActive || false,
    });

    useEffect(() => {
        setFormValues({
            ...formValues,
            bio: currentBasicDetail?.bio || "",
            skills: currentBasicDetail?.skills || [],
            description: currentBasicDetail?.description || "",
            dob: currentBasicDetail?.dob || "",
            links: currentBasicDetail?.links || [],
            isActive: currentBasicDetail?.isActive || false,
        });
    }, [currentBasicDetail]);

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormValues(prevValues => ({
            ...prevValues,
            [name]: type === "checkbox" ? checked : value,
        }));
    };

    const handleDescriptionChange = (value) => {
        setFormValues(prevValues => ({
            ...prevValues,
            description: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        // Handle form submission
        console.log("Submitted Form Values:", formValues);
        updateProfileInfoDetails(currentBasicDetail._id, formValues);
        setTimeout(() => {
            setLoading(false);
            handleModalClose();
        }, 2000);
    };
    return (
        <div className="col-lg-6 bg-white rounded-sm py-3 relative">
            <span
                className="absolute right-4 cursor-pointer"
                onClick={handleModalClose}
                aria-label="Close"
            >
                <MdClose />
            </span>
            <h4 className="font-semibold fs-4 text-[#070f4e]">
                Edit Professional  Information
            </h4>
            <form onSubmit={handleSubmit} className="py-3">
                <div className="row">
                    <div className="form-group col mb-3">
                        <label htmlFor="locality">
                            Bio <sup className="text-danger">*</sup>
                        </label>
                        <input
                            type="text"
                            className="form-input"
                            placeholder="e.g. House No., Block, Town"
                            name="bio"
                            value={formValues.bio}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    <div className="form-group col mb-3">
                        <label htmlFor="dob">
                            Date of Birth <sup className="text-danger">*</sup>
                        </label>
                        <input
                            type="date"
                            className="form-input"
                            placeholder="e.g. House No., Block, Town"
                            name="dob"
                            value={formValues.dob}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                </div>
                <div className="form-group mb-16">
                    <label htmlFor="country">
                        Profile summary <sup className="text-danger">*</sup>
                    </label>
                    <ReactQuill
                        id="description"
                        name="description"
                        theme="snow"
                        modules={{
                            toolbar: [
                                ["bold", "italic", "underline"],
                                [{ list: "ordered" }, { list: "bullet" }],
                            ],
                        }}
                        style={{ height: "200px" }}
                        onChange={handleDescriptionChange}
                    />
                </div>
                <div className="form-group mb-3">
                    <label htmlFor="links">
                        Language known<sup className="text-danger">*</sup>
                    </label>
                    <TagsInput
                        value={selected}
                        onChange={(newSelected) => {
                            setSelected(newSelected);
                            setFormValues((prevData) => ({
                                ...prevData,
                                links: newSelected,
                            }));
                        }}
                        name="links"
                        placeHolder="press enter to add tags"
                    />
                </div>
                <div className="form-group mb-3">
                    <label htmlFor="skills">
                        Skills<sup className="text-danger">*</sup>
                    </label>
                    <TagsInput
                        value={tags}
                        onChange={(newTags) => {
                            setTags(newTags);
                            setFormValues((prevData) => ({
                                ...prevData,
                                skills: newTags,
                            }));
                        }}
                        name="skills"
                        placeHolder="press enter to add tags"
                    />
                </div>
                <div className="form-check mb-3">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        name="isActive"
                        checked={formValues.isActive}
                        onChange={handleInputChange}
                        id="isActive"
                    />
                    <label className="form-check-label" htmlFor="isActive">
                        Permanent address is same
                    </label>
                </div>
                <div className="form-group mb-3">
                    <button
                        type="submit"
                        className={`btn-main ${loading ? "bg-gray-400" : "bg-[#272343]"} text-light`}
                        disabled={loading}
                    >
                        {loading ? "updating..." : "update"}
                    </button>        </div>
            </form>
        </div>
    );
};

export default BasicDetailUpdateModal;