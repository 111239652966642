import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
const ModalBox = ({closeModal, children}) => {
    useEffect(() => {
        document.body.style.overflow = "hidden";
        return () => {
            document.body.style.overflow = "scroll"
        }
    }, []);
  return ReactDOM.createPortal(
    <>
        <div className='modal-wrapper container-fluid' onClick={closeModal}>
            <div className='modal-container h-[100vh] row items-center justify-center'>{children}</div>
        </div>
    </>,
    document.querySelector('.modalBox')
  );
}

export default ModalBox