import React from 'react'
import BasicDetailsForm from './profileForms/BasicDetailsForm'
import { Link } from 'react-router-dom'
import { TabMenu } from '../../../components'

const EmployeeProfile = () => {
  const tabs = ["Basic Details", "Recruiter Action",]
  const children = [<BasicDetailsForm />]
  return (
    <>
      <div className="container-fluid">
        <div className="container-sm">
          <div className="row py-2">
            <h4 className='text-2xl font-semibold text-[#8dc6ff]'>My Profile</h4>
          </div>
          <div className="row mb-3">
            <div className="col-lg">
              <div className="col-lg-2 flex flex-col">
                <img src="https://i.pravatar.cc/300" alt="" className='img-thumbnail rounded-circle' />
                <label htmlFor="" className='h6'>Profile picture</label>
                <Link className='nav-link text-primary'>edit</Link>
              </div>
            </div>
          </div>
          <TabMenu tabs={tabs} children={children} />
        </div>
      </div>
    </>
  )
}

export default EmployeeProfile