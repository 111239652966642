const BASE_URL = 'https://api.adviksingh.org/api/v1';

export const loginUserURL = `${BASE_URL}/auth/login/user`;
export const registerUserURL = `${BASE_URL}/user/add-user`

export const addSectionDataUrl = `${BASE_URL}/section/add-section`
export const fetchAllSectionsUrl = `${BASE_URL}/section/get-all-sections`
export const fetchSectionsByIdEndpoint = `${BASE_URL}/section/get-section/id`


export const addJobUrl = `${BASE_URL}/product/add-product`
export const fetchAllProducts = `${BASE_URL}/product/get-all-products`
export const fetchAllProductByUserId = `${BASE_URL}/product/get-product/sellerId`
export const fetchAllProductById = `${BASE_URL}/product/get-product/id`
export const deleteProductById = `${BASE_URL}/product/delete-account/id`

//admin API
export const fetchAdminProfile = `${BASE_URL}/admin/get-admin/profile`
export const addAdminProfile = `${BASE_URL}/admin/create-profile`

export const createJobApplicationAPI = `${BASE_URL}/job/create/application`;
export const fetchJobApplicationByUserIdAPI = `${BASE_URL}/job/get-user/application`;
export const fetchJobApplicationBySellerIdAPI = `${BASE_URL}/job/get-seller/application`;


export const fetchEmployeeApplicationById = `${BASE_URL}/profile/get-user-details`;
export const createEmployeeEducationDetails = `${BASE_URL}/profile/add-education-details`;
export const createEmployeeExperienceDetails = `${BASE_URL}/profile/add-experience-details`;
export const createEmployeeAddressDetails = `${BASE_URL}/profile/add-address-details`;
export const createEmployeeInfoDetails = `${BASE_URL}/profile/add-info-details`;



export const deleteEmployeeEducationDetails = `${BASE_URL}/profile/delete-education-details`;
export const deleteEmployeeExperienceDetails = `${BASE_URL}/profile/delete-experience-details`;

export const updateEmployeeEducationDetails = `${BASE_URL}/profile/update-education-details`;
export const updateEmployeeExperienceDetails = `${BASE_URL}/profile/update-experience-details`;
export const updateEmployeeInfoDetails = `${BASE_URL}/profile/update-info-details`;
export const updateEmployeeAddressDetails = `${BASE_URL}/profile/update-address-details`;


