import React, { useState } from 'react';
import { MdClose } from 'react-icons/md';
import { useEmployeeContext } from '../../../../context';

const EducationModal = ({ handleModalClose }) => {
    const { addEducationDetails } = useEmployeeContext();
    const user = JSON.parse(sessionStorage.getItem('data'));
    const [values, setValues] = useState({
        userId: user._id,
        title: '',
        university: '',
        institute: '',
        startedAt: '',
        endedAt: '',
        percentage: '',
        isActive: false,
    });

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setValues({
            ...values,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission
        console.log(values);
        addEducationDetails(values);
    };

    return (
        <div className="col-lg-6 bg-white rounded-sm py-3 relative">
            <span
                className="absolute right-4 cursor-pointer"
                onClick={handleModalClose}
                aria-label="Close"
            >
                <MdClose />
            </span>
            <h4 className="font-semibold fs-4 text-[#070f4e]">
                Add Education Information
            </h4>
            <form onSubmit={handleSubmit} className='py-3'>
                <div className="form-group mb-3">
                    <label htmlFor='university'>University name <sup className='text-danger'>*</sup></label>
                    <input
                        type="text"
                        className='form-input'
                        placeholder='University name'
                        name="university"
                        value={values.university}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="form-group mb-3">
                    <label htmlFor='institute'>Institute/ College name <sup className='text-danger'>*</sup></label>
                    <input
                        type="text"
                        className='form-input'
                        placeholder='Institute/ College name'
                        name="institute"
                        value={values.institute}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="form-group mb-3">
                    <label htmlFor='title'>Degree <sup className='text-danger'>*</sup></label>
                    <input
                        type="text"
                        className='form-input'
                        placeholder='Degree'
                        name="title"
                        value={values.title}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="form-group mb-3">
                    <label htmlFor='percentage'>CGPA/ Percentage <sup className='text-danger'>*</sup></label>
                    <input
                        type="text"
                        className='form-input'
                        placeholder='CGPA/ Percentage'
                        name="percentage"
                        value={values.percentage}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="row">
                    <div className="col">
                        <div className="form-group mb-3">
                            <label htmlFor='startedAt'>Started at <sup className='text-danger'>*</sup></label>
                            <input
                                type="date"
                                className='form-input'
                                name="startedAt"
                                value={values.startedAt}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                    </div>
                    <div className="col">
                        <div className="form-group mb-3">
                            <label className='endedAt'>Ended at <sup className='text-danger'>*</sup></label>
                            <input
                                type="date"
                                className='form-input'
                                name="endedAt"
                                value={values.endedAt}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                    </div>
                </div>
                <div className="form-check mb-3">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        name="isActive"
                        checked={values.isActive}
                        onChange={handleInputChange}
                        id="flexCheckDefault"
                    />
                    <label className="form-check-label" htmlFor="isActive">
                        Currently pursuing
                    </label>
                </div>
                <div className="form-group mb-3">
                    <button type="submit" className='btn-main bg-[#272343] text-light'>
                        Submit
                    </button>
                </div>
            </form>
        </div>
    );
};

export default EducationModal;
