import React, { useState } from "react";

const FormStepOne = ({ nextStep, handleInputChange, formData }) => {
  const [formErrors, setFormErrors] = useState({
    firstName: "",
    lastName: "",
    role: "",
    email: "",
    mobile: "",
    password: "",
    confirmPassword: "",
  });

  const [confirmPassword, setConfirmPassword] = useState("");

  const validateForm = () => {
    let isValid = true;
    const errors = {};

    if (!formData.firstName.trim()) {
      errors.firstName = "First Name is required";
      isValid = false;
    }

    if (!formData.lastName.trim()) {
      errors.lastName = "Last Name is required";
      isValid = false;
    }

    if (!formData.email.trim()) {
      errors.email = "Email is required";
      isValid = false;
    }

    if (!formData.mobile.trim()) {
      errors.mobile = "Mobile number is required";
      isValid = false;
    } else if (!/^\d{10}$/.test(formData.mobile)) {
      errors.mobile = "Mobile number must be 10 digits and no alphabets";
      isValid = false;
    }

    if (!formData.role) {
      errors.role = "Please select an option";
      isValid = false;
    }

    const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/;

    if (!formData.password.trim()) {
      errors.password = "Password is required";
      isValid = false;
    } else if (!passwordPattern.test(formData.password)) {
      errors.password = "Password must be at least 8 characters long, include an uppercase letter, a lowercase letter, a number, and a special character";
      isValid = false;
    }

    if (formData.password !== confirmPassword) {
      errors.confirmPassword = "Passwords do not match";
      isValid = false;
    }

    setFormErrors(errors);
    return isValid;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      nextStep();
    }
  };

  return (
    <>
      <div className="form-group mb-3">
        <label htmlFor="" className="text-[13px] font-semibold mb-1">
          What are you looking for
        </label>
        <select className="form-input" onChange={handleInputChange("role")} value={formData.role}>
          <option value="">Choose an option</option>
          <option value="Employee">Job opportunity</option>
          <option value="Employer">Hire candidates</option>
        </select>
        {formErrors.role && <p className="text-danger text-sm">{formErrors.role}</p>}
      </div>
      <div className="form-group mb-3">
        <label htmlFor="" className="text-[13px] font-semibold mb-1">
          First Name <sup className="text-danger">*</sup>
        </label>
        <input
          type="text"
          className="form-input"
          placeholder="first name"
          name="firstName"
          value={formData.firstName}
          onChange={handleInputChange("firstName")}
        />
        {formErrors.firstName && <p className="text-danger text-sm">{formErrors.firstName}</p>}
      </div>
      <div className="form-group mb-3">
        <label htmlFor="" className="text-[13px] font-semibold mb-1">
          Last Name <sup className="text-danger">*</sup>
        </label>
        <input
          type="text"
          className="form-input"
          placeholder="last name"
          name="lastName"
          value={formData.lastName}
          onChange={handleInputChange("lastName")}
        />
        {formErrors.lastName && <p className="text-danger text-sm">{formErrors.lastName}</p>}
      </div>
      <div className="form-group mb-3">
        <label htmlFor="" className="text-[13px] font-semibold mb-1">
          Mobile
        </label>
        <input
          type="text"
          className="form-input"
          placeholder="enter mobile number"
          name="mobile"
          value={formData.mobile}
          onChange={handleInputChange("mobile")}
        />
        {formErrors.mobile && <p className="text-danger text-sm">{formErrors.mobile}</p>}
      </div>
      <div className="form-group mb-3">
        <label htmlFor="" className="text-[13px] font-semibold mb-1">
          Email <sup className="text-danger">*</sup>
        </label>
        <input
          type="text"
          className="form-input"
          placeholder="e.g. abc@example.com"
          name="email"
          value={formData.email}
          onChange={handleInputChange("email")}
        />
        {formErrors.email && <p className="text-danger text-sm">{formErrors.email}</p>}
      </div>
      <div className="form-group mb-3">
        <label htmlFor="" className="text-[13px] font-semibold mb-1">
          Password <sup className="text-danger">*</sup>
        </label>
        <input
          type="password"
          className="form-input"
          placeholder="enter password"
          name="password"
          value={formData.password}
          onChange={handleInputChange("password")}
        />
        {formErrors.password && <p className="text-danger text-sm">{formErrors.password}</p>}
      </div>
      <div className="form-group mb-3">
        <label htmlFor="" className="text-[13px] font-semibold mb-1">
          Confirm Password <sup className="text-danger">*</sup>
        </label>
        <input
          type="password"
          className="form-input"
          placeholder="confirm password"
          name="confirmPassword"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        {formErrors.confirmPassword && <p className="text-danger text-sm">{formErrors.confirmPassword}</p>}
      </div>
      <div className="form-group pt-3 flex gap-3 items-center">
        <button onClick={handleSubmit} className="btn-main bg-[#070f4e] text-white">
          Submit
        </button>
      </div>
    </>
  );
};

export default FormStepOne;
