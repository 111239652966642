import React from 'react'
import { Link } from 'react-router-dom'

const FormStep2 = ({nextStep, prevStep, handleInputChange, handleSubmit}) => {
  return (
    <>
        
        <div className="form-group pt-3 flex gap-3 items-center">
          <button onClick={handleSubmit} className="btn-main bg-[#070f4e] text-white">Submit</button>
          <button onClick={prevStep} className="btn-main bg-[#070f4e] text-white">Back</button>
        </div>
    </>
  )
}

export default FormStep2