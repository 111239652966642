import React, { useState, useEffect } from 'react';
import { JobCard } from '../shared';
import { useJobContext, useSectionContext } from '../context';
import { experienceLevel, jobType } from '../testData/StaticData';

const AllJobs = () => {
  const { allJobs } = useJobContext();
  const { sections } = useSectionContext();
  const [selectedExperience, setSelectedExperience] = useState([]);
  const [selectedJobTypes, setSelectedJobTypes] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [filteredJobs, setFilteredJobs] = useState([]);

  useEffect(() => {
    setFilteredJobs(allJobs);
  }, [allJobs]);

  const handleFilterChange = (setFilterState, filterState, value) => {
    if (filterState.includes(value)) {
      setFilterState(filterState.filter(item => item !== value));
    } else {
      setFilterState([...filterState, value]);
    }
  };

  const applyFilters = () => {
    let filtered = allJobs;

    if (selectedExperience.length > 0) {
      filtered = filtered.filter(job => selectedExperience.includes((job.experience || '').trim()));
    }

    if (selectedJobTypes.length > 0) {
      filtered = filtered.filter(job => selectedJobTypes.includes((job.jobType || '').trim()));
    }

    if (selectedCategories.length > 0) {
      filtered = filtered.filter(job => selectedCategories.includes((job.category || '').trim()));
    }

    setFilteredJobs(filtered);
  };

  return (
    <>
      <div className="container-fluid">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 py-3">
              <div className='mb-3 flex justify-between items-center'>
                <h4 className='text-2xl'>Filter jobs</h4>
                <button className='btn-main bg-[#272343] text-white' onClick={applyFilters}>Filter</button>
              </div>
              <div className="form-group border px-2 py-2 mb-3">
                <p className='text-[18px] mb-3'>Categories</p>
                {sections?.map((sec, idx) => (
                  <div key={idx} className='form-check flex items-center gap-3 mb-2'>
                    <input
                      type="checkbox"
                      className='form-check-label'
                      onChange={() => handleFilterChange(setSelectedCategories, selectedCategories, (sec._id || '').trim())}
                    />
                    <label className='text-[14px]' htmlFor="">{sec.title}</label>
                  </div>
                ))}
              </div>
              <div className="form-group border px-2 py-2 mb-3">
                <p className='text-[18px] mb-3'>Job type</p>
                {jobType?.map((sec, idx) => (
                  <div key={idx} className='form-check flex items-center gap-3 mb-2'>
                    <input
                      type="checkbox"
                      className='form-check-label'
                      onChange={() => handleFilterChange(setSelectedJobTypes, selectedJobTypes, (sec.title || '').trim())}
                    />
                    <label className='text-[14px]' htmlFor="">{sec.title}</label>
                  </div>
                ))}
              </div>
              <div className="form-group border px-2 py-2 mb-3">
                <p className='text-[18px] mb-3'>Experience level</p>
                {experienceLevel?.map((sec, idx) => (
                  <div key={idx} className='form-check flex items-center gap-3 mb-2'>
                    <input
                      type="checkbox"
                      className='form-check-label'
                      onChange={() => handleFilterChange(setSelectedExperience, selectedExperience, (sec.title || '').trim())}
                    />
                    <label className='text-[14px]' htmlFor="">{sec.title}</label>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-lg py-3">
              <h4 className='text-2xl mb-3'>Open Positions</h4>
              {filteredJobs.length === 0 ? (
                <p className='text-2xl font-bold text-blue-500'>No jobs found !</p>
              ) : (
                <JobCard data={filteredJobs} />
              )}
            </div>
            <div className="col-3"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllJobs;
