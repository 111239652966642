import React, { useEffect, useState } from 'react';
import { useEmployeeContext } from '../../../../context';
import { ModalBox } from '../../../../components';
import EducationModal from '../profileModals/EducationModal';
import { confirmAlert } from 'react-confirm-alert';
import EducationUpdateModal from '../profileUpdateModals/EducationUpdateModal';

const EducationForm = () => {
    const { getEmployeeProfileDetails, deleteEducationDetails, formatDate } = useEmployeeContext();
    const [educationDetail, setEducationDetail] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [openUpdateModal, setOpenUpdateModal] = useState(false);
    const [currentEducation, setCurrentEducation] = useState(null);

    const handleModalClose = () => {
        setOpenModal(false);
        setOpenUpdateModal(false);
        setCurrentEducation(null);
    }

    const handleDeleteConfirmBox = (id) => {
        confirmAlert({
            title: 'Confirm to delete',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => deleteEducationDetails(id)
                },
                {
                    label: 'No',
                    onClick: () => ({})
                }
            ]
        });
    }

    const handleEditClick = (education) => {
        setCurrentEducation(education);
        setOpenUpdateModal(true);
    }

    useEffect(() => {
        const fetchEducationDetails = async () => {
            try {
                const result = await getEmployeeProfileDetails();
                setEducationDetail(result.data.education);
            } catch (error) {
                console.error("Error fetching Education details:", error);
            }
        }

        fetchEducationDetails();
    }, [getEmployeeProfileDetails]);
    return (
        <>
            <div className="row mt-3 border py-3">
                <div className="group-box flex items-center justify-between">
                    <h4 className='heading_4'>Education Information</h4>
                    <div className="flex items-center gap-3">
                        <button onClick={() => setOpenModal(true)} className='btn-main bg-[#272343] text-light'>Add education</button>
                    </div>
                </div>
                {educationDetail ? (
                    <div className="col-lg py-3">
                        {educationDetail.map((education, idx) => (
                            <div key={idx} className='card mb-3'>
                                <div className="card-body">
                                    <h5 className="card-title font-semibold">{education?.title}</h5>
                                    <p className='card-text text-[14px]'><span className='font-semibold'>University</span> {education?.university}</p>
                                    <p className='card-text text-[14px]'><span className='font-semibold'>Collage/ Institute</span> {education?.institute}</p>
                                    <p className='card-text text-[14px]'><span className='font-semibold'>Percentage/ CGPA</span> {education?.percentage}</p>
                                    <p className='flex items-center gap-3'>
                                        <span className='text-[14px] text-secondary font-semibold'>Started at {formatDate(education.startedAt)}</span>
                                        -
                                        <span className='text-[14px] text-secondary font-semibold'>Ended at {formatDate(education.endedAt)}</span>
                                    </p>
                                </div>
                                <div className="card-footer flex items-center gap-3 justify-end">
                                    <button onClick={() => handleDeleteConfirmBox(education._id)} className='btn-main bg-danger text-light'>remove</button>
                                    <button onClick={() => handleEditClick(education)} className='btn-main bg-[#8dc6ff]'>edit</button>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : null}
            </div>
            {openModal && (
                <ModalBox>
                    <EducationModal handleModalClose={handleModalClose} />
                </ModalBox>
            )}
            {openUpdateModal && currentEducation && (
                <ModalBox>
                    <EducationUpdateModal education={currentEducation} handleModalClose={handleModalClose} />
                </ModalBox>
            )}
        </>
    );
}

export default EducationForm;
