import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuthContext } from "../../context";

const Login = () => {
  const { AuthenticateUser, token } = useAuthContext();
  const location = useLocation();
  const navigate = useNavigate();
  const [signup, setSignup] = useState(true);
  const [loginCredentials, setLoginCredentials] = useState({
    email: "",
    password: "",
  });
  const [formError, setFormError] = useState({
    email: false,
    password: false,
  });

  const validateFormInput = () => {
    const errors = {};
    if (loginCredentials.email.trim() === "" || loginCredentials.email === null) {
      errors.email = true;
    }
    if (loginCredentials.password.trim() === "" || loginCredentials.password === null) {
      errors.password = true;
    }
    setFormError(errors);
    return Object.keys(errors).length === 0;
  };

  useEffect(() => {
    let id;
    if (token) {
      id = setTimeout(() => {
        navigate(location?.state?.from?.pathname ?? "/");
      }, 1000);
    }

    return () => {
      clearInterval(id);
    };
  }, [token]);

  const handleInputChange = (e) => {
    setLoginCredentials({
      ...loginCredentials,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateFormInput()) {
      try {
        console.log(loginCredentials);
        await AuthenticateUser(loginCredentials);
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="container-sm">
          <div className="row">
            <div className="col-lg"></div>
            <div className="col-lg">
              <h4 className="font-semibold fs-2 text-[#070f4e]">
                {!signup ? "Sign up" : "Log in"}
              </h4>
              <form className="pt-3" onSubmit={handleSubmit}>
                <div className="form-group mb-3">
                  <label htmlFor="" className="text-[13px] font-semibold mb-1">Username or Email <sup className="text-danger">*</sup></label>
                  <input type="email" className="form-input" name="email" value={loginCredentials.email} onChange={handleInputChange} placeholder="Username or Email" />
                  {formError.email && ( <p className="text-danger">Email is required</p>)}
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="" className="text-[13px] font-semibold mb-1"> Password <sup className="text-danger">*</sup></label>
                  <input type="password" className="form-input" name="password" value={loginCredentials.password} onChange={handleInputChange} placeholder="Password" />
                  {formError.password && ( <p className="text-danger">Password is required</p>)}
                </div>
                <div className="form-group mb-3 flex gap-3 items-end">
                  <button type="submit" className="btn-main bg-[#070f4e] text-white">Log in</button>
                  <p className="text-[14px]">Don't have an account ?{" "}
                    <Link to="/register" className="text-blue-700 font-semibold" > Create an account</Link>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
