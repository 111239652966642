import React, { useState } from "react";
import { MdClose } from "react-icons/md";
import { useEmployeeContext } from "../../../../context";
import ReactQuill from "react-quill";

const AddressModal = ({ handleModalClose }) => {
  const { addAddressDetails } = useEmployeeContext();
  const user = JSON.parse(sessionStorage.getItem("data"));
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState({
    userId: user._id,
    locality: '', //{ type: String, required: true },
    city: '', //{ type: String, required: true },
    state: '',
    pincode : '', // { type: String, required: true },
    country: '', // { type: String, required: true },
    isPermanent:false // { type: Boolean, required: false },
  });

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormValues({
      ...formValues,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission
    console.log(formValues);
    addAddressDetails(formValues);
    setLoading(true);
    setTimeout(() => {
      handleModalClose();
    }, 2000);
  };

  return (
    <div className="col-lg-6 bg-white rounded-sm py-3 relative">
      <span
        className="absolute right-4 cursor-pointer"
        onClick={handleModalClose}
        aria-label="Close"
      >
        <MdClose />
      </span>
      <h4 className="font-semibold fs-4 text-[#070f4e]">
        Add Address Information
      </h4>
      <form onSubmit={handleSubmit} className="py-3">
        <div className="form-group mb-3">
          <label htmlFor="locality">
            Local area <sup className="text-danger">*</sup>
          </label>
          <input
            type="text"
            className="form-input"
            placeholder="e.g. House No. , Block, Town"
            name="locality"
            value={formValues.locality}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group mb-3">
              <label htmlFor="country">
                Country <sup className="text-danger">*</sup>
              </label>
              <input
                type="text"
                className="form-input"
                name="country"
                value={formValues.country}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-group mb-3">
              <label htmlFor="state">
                State<sup className="text-danger">*</sup>
              </label>
              <input
                type="text"
                className="form-input"
                name="state"
                value={formValues.state}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-group mb-3">
              <label htmlFor="city">
                City<sup className="text-danger">*</sup>
              </label>
              <input
                type="text"
                className="form-input"
                name="city"
                value={formValues.city}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-group mb-3">
              <label htmlFor="pincode">
                Pincode <sup className="text-danger">*</sup>
              </label>
              <input
                type="text"
                className="form-input"
                name="pincode"
                value={formValues.pincode}
                onChange={handleInputChange}
                required
              />
            </div>
        <div className="form-check mb-3">
          <input
            className="form-check-input"
            type="checkbox"
            name="isPermanent"
            checked={formValues.isPermanent}
            onChange={handleInputChange}
            id="isPermanent"
          />
          <label className="form-check-label" htmlFor="isPermanent">
            Permanent address is same
          </label>
        </div>
        <div className="form-group mb-3">
          <button
            type="submit"
            className={`btn-main ${
              loading ? "bg-gray-400" : "bg-[#272343]"
            } text-light`}
            disabled={loading}
          >
            {loading ? "Submitting..." : "Submit"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddressModal;
