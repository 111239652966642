import React, { useEffect, useState } from 'react';
import { useEmployeeContext } from '../../../../context';
import { Link } from 'react-router-dom';
import { ModalBox } from '../../../../components';
import ProfessionalDetailsModal from '../profileModals/ProfessionalDetailsModal';
import DOMPurify from 'dompurify';
import BasicDetailUpdateModal from '../profileUpdateModals/BasicDetailUpdateModal';


const ProfessionalDetailsForm = () => {
    const { getEmployeeProfileDetails } = useEmployeeContext();
    const [professionalDetails, setProfessionalDetails] = useState(null); // Initialize as null
    const [openModal, setOpenModal] = useState(false);
    const [openUpdateModal, setOpenUpdateModal] = useState(false);
    const [currentBasicDetail, setCurrentBasicDetail] = useState(null);
    const handleModalClose = () => {
        setOpenModal(false)
        setOpenUpdateModal(false);
        setCurrentBasicDetail(null);
    }

    useEffect(() => {
        const fetchProfessionalDetailsDetails = async () => { 
            try {
                const result = await getEmployeeProfileDetails(); // Call the function to get the result
                setProfessionalDetails(result.data.personalInfo[0]);
            } catch (error) {
                console.error("Error fetching professionalDetails details:", error);
                // Handle error
            }
        }

        fetchProfessionalDetailsDetails();
    }, [getEmployeeProfileDetails]); 
    
    const handleEditClick = (basicDetail) => {
        setCurrentBasicDetail(basicDetail);
        setOpenUpdateModal(true);
    };// Add getEmployeeProfileDetails to dependency array
    return (
        <div className="row mt-3 border py-3 rounded">
            <div className="group-box flex items-center justify-between">
                <h4 className='heading_4'>Professional Summary</h4>
                <button onClick={() =>handleEditClick(professionalDetails)} className='btn-main bg-[#272343] text-light'>change professionalDetails</button>
            </div>
            <div className="col-lg py-3">
                {professionalDetails ? (
                    <div className="col-lg">
                        <div className="form-group mb-3">
                            <span className="text-[14px]" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(professionalDetails?.description) }} />
                        </div>
                        <div className="card mb-3">
                            <div className="card-header">
                                <span className='card-title'>Skills</span>
                            </div>
                            <div className="card-body flex items-center gap-3">
                                {professionalDetails?.skills?.map((skills, idx) => (
                                    <span className='bg-slate-300 px-2 rounded text-[14px]' key={idx} >{skills}</span>
                                ))}
                            </div>
                        </div>
                        <div className="card mb-3">
                            <div className="card-header">
                                <span className='card-title'>Language known</span>
                            </div>
                            <div className="card-body flex items-center gap-3">
                                {professionalDetails?.links?.map((links, idx) => (
                                    <span className='bg-slate-300 px-2 rounded text-[14px]' key={idx} >{links}</span>
                                ))}
                            </div>
                        </div>
                    </div>
                ) : (
                    <Link onClick={() => setOpenModal(true)} className='text-primary nav-link'>Click to add details</Link>
                )}
             </div>
            {openModal ?<ModalBox>
                <ProfessionalDetailsModal handleModalClose={handleModalClose} />
            </ModalBox> : null}
            {openUpdateModal && (
                <ModalBox>
                    <BasicDetailUpdateModal handleModalClose={handleModalClose} currentBasicDetail={currentBasicDetail} />
                </ModalBox>
            )}
        </div>
    );
}

export default ProfessionalDetailsForm;
