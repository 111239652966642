import React, { useEffect, useState } from 'react'
import { useEmployeeContext } from '../../../../context';
import { Link } from 'react-router-dom';
import DOMPurify from 'dompurify';
import { ModalBox } from '../../../../components';
import ExperienceModal from '../profileModals/ExperienceModal';
import ExperienceUpdateModal from '../profileUpdateModals/ExperienceUpdateModal';
import { confirmAlert } from 'react-confirm-alert';

const ExperienceForm = () => {
    const { getEmployeeProfileDetails, formatDate, deleteExperienceDetails } = useEmployeeContext();
    const [experienceDetail, setExperienceDetail] = useState(null); // Initialize as null
    const [openModal, setOpenModal] = useState(false);
    const [openUpdateModal, setOpenUpdateModal] = useState(false);
    const [currentExperience, setCurrentExperience] = useState(null);

    const handleModalClose = () => {
        setOpenModal(false);
        setOpenUpdateModal(false);
        setCurrentExperience(null);
    };

    const handleDeleteConfirmBox = (id) => {
        confirmAlert({
            title: 'Confirm to delete',
            message: 'Are you sure to do this.',
            buttons: [
              {
                label: 'Yes',
                onClick: () => deleteExperienceDetails(id)
              },
              {
                label: 'No',
                onClick: () => ({})
              }
            ]
          });
    };

    useEffect(() => {
        const fetchExperienceDetails = async () => {
            try {
                const result = await getEmployeeProfileDetails();
                setExperienceDetail(result.data.experience);
            } catch (error) {
                console.error("Error fetching Experience details:", error);
            }
        };

        fetchExperienceDetails();
    }, [getEmployeeProfileDetails]); // Add getEmployeeProfileDetails to dependency array

    const handleEditClick = (experience) => {
        setCurrentExperience(experience);
        setOpenUpdateModal(true);
    };

    return (
        <>
            <div className="row mt-3 border py-3">
                <div className="group-box flex items-center justify-between">
                    <h4 className='heading_4'>Experience Information</h4>
                    <div className="flex items-center gap-3">
                        <button onClick={() => setOpenModal(true)} className='btn-main bg-[#272343] text-light'>Add experience</button>
                    </div>
                </div>
                {experienceDetail ? (
                    <div className="col-lg py-3">
                        {experienceDetail?.map((detail, idx) => (
                            <div key={idx} className="card mb-3">
                                <div className="card-body">
                                    <h6 className='card-title font-semibold text-secondary'>{detail?.title}</h6>
                                    <span>{detail?.position}</span>
                                    <div className="row items-center py-2">
                                        <div className="col flex flex-col">
                                            <span className='text-[14px] font-semibold text-secondary'>Started at</span>
                                            <span className='text-[14px]'>{formatDate(detail?.startedAt)}</span>
                                        </div>
                                        <div className="col flex flex-col">
                                            <span className='text-[14px] font-semibold text-secondary'>Ended at</span>
                                            <span className='text-[14px]'>{formatDate(detail?.endedAt)}</span>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <h4 className='h6 font-semibold text-secondary'>Roles & Responsibility</h4>
                                        <span className="text-[14px]" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(detail?.description) }} />
                                    </div>
                                </div>
                                <div className="card-footer flex items-center gap-3 justify-end">
                                    <button onClick={() => handleDeleteConfirmBox(detail._id)} className='btn-main bg-danger text-light'>remove</button>
                                    <button onClick={() => handleEditClick(detail)} className='btn-main bg-[#8dc6ff]'>edit</button>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className="container-sm">
                        <Link to="#" onClick={() => setOpenModal(true)} className='text-primary nav-link'>Click here to add details</Link>
                    </div>
                )}
            </div>
            {openModal && (
                <ModalBox>
                    <ExperienceModal handleModalClose={handleModalClose} />
                </ModalBox>
            )}
            {openUpdateModal && currentExperience && (
                <ModalBox>
                    <ExperienceUpdateModal experience={currentExperience} handleModalClose={handleModalClose} />
                </ModalBox>
            )}
        </>
    )
}

export default ExperienceForm;
