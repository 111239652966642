import React from 'react'
import { Link } from 'react-router-dom'
import { confirmAlert } from 'react-confirm-alert';
import { useAuthContext } from '../../context';

const EmployerHeader = () => {
  const {logoutUser} = useAuthContext();
  const Logout = () => {
    confirmAlert({
      title: 'Confirm to logout',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => logoutUser()
        },
        {
          label: 'No',
          onClick: () => ({})
        }
      ]
    });
  }
  return (
    <>
      <div className="bg-slate-100">
        <nav className="mx-8 flex justify-between items-center py-2">
          <Link to='/' className="font-bold text-xl">RESO</Link>
          <ul className="flex items-center gap-3">
            <li>
              <Link to='/add-job' className="text-[14px]">Add Job</Link>
            </li>
            <li>
                <Link to='/add-category' className="text-[14px]">Category</Link>
            </li>
            <li>
                <Link to='/view-profile' className="text-[14px]">View Profile</Link>
            </li>
            <li>
              <button onClick={Logout} className='btn-main bg-[#272343] text-light'>Log out</button>
            </li>
          </ul>
        </nav>
      </div>
    </>
  )
}

export default EmployerHeader