import React from 'react'

const Search = ({placehoder}) => {
  return (
    <>
        <div className='py-3'>
            <div className='flex'>
                <input type="text" className='bg-slate-100 form-input' placeholder={placehoder} />
                <button className='btn-main bg-[#070f4e] text-white'>Search</button>
            </div>
        </div>
    </>
  )
}

export default Search