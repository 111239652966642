import React, { useEffect, useState } from 'react';
import { MdClose } from 'react-icons/md';
import { useEmployeeContext } from '../../../../context';
import ReactQuill from 'react-quill';

const ExperienceUpdateModal = ({ handleModalClose, experience }) => {
    const { updateExperienceDetails } = useEmployeeContext();
    const user = JSON.parse(sessionStorage.getItem('data'));
    const [loading, setLoading] = useState(false);
    const [values, setValues] = useState({
        userId: user._id,
        title: experience.title,
        position: experience.position,
        description: experience.description,
        startedAt: experience.startedAt,
        endedAt: experience.endedAt,
        isActive: experience.isActive,
    });
    useEffect(() => {
        setValues({
            userId: user._id,
            title: experience.title,
            position: experience.position,
            description: experience.description,
            startedAt: experience.startedAt,
            endedAt: experience.endedAt,
            isActive: experience.isActive,
        });
    }, [experience]);
    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setValues({
            ...values,
            [name]: type === 'checkbox' ? checked : value,
        });
    };
    const handleDescriptionChange = (value, delta, source, editor) => {
        setValues((prevData) => ({
          ...values,
          description: value,
        }));
      };
    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("this is updated exp", values)
        updateExperienceDetails(experience._id, values);
        setLoading(true);
        setTimeout(() => {
            handleModalClose();
        }, 2000);
    };


    return (
        <div className="col-lg-6 bg-white rounded-sm py-3 relative">
            <span
                className="absolute right-4 cursor-pointer"
                onClick={handleModalClose}
                aria-label="Close"
            >
                <MdClose />
            </span>
            <h4 className="font-semibold fs-4 text-[#070f4e]">
                Update Experience Information
            </h4>
            <form onSubmit={handleSubmit} className='py-3'>
                <div className="form-group mb-3">
                    <label htmlFor='title'>Company name <sup className='text-danger'>*</sup></label>
                    <input
                        type="text"
                        className='form-input'
                        placeholder='University name'
                        name="title"
                        value={values.title}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="form-group mb-3">
                    <label htmlFor='position'>Job role<sup className='text-danger'>*</sup></label>
                    <input
                        type="text"
                        className='form-input'
                        name="position"
                        value={values.position}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="form-group mb-12">
                    <label htmlFor='title'>Job Description <sup className='text-danger'>*</sup></label>
                    <ReactQuill
                        id="description"
                        name="description"
                        theme="snow"
                        value={values.description}
                        modules={{
                            toolbar: [
                                ["bold", "italic", "underline"],
                                [{ list: "ordered" }, { list: "bullet" }],
                            ],
                        }}
                        style={{ height: "200px" }}
                        onChange={handleDescriptionChange}
                    />
                </div>
                <div className="row">
                    <div className="col">
                        <div className="form-group mb-3">
                            <label htmlFor='startedAt'>Started at <sup className='text-danger'>*</sup></label>
                            <input
                                type="date"
                                className='form-input'
                                name="startedAt"
                                value={values.startedAt}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                    </div>
                    <div className="col">
                        <div className="form-group mb-3">
                            <label className='endedAt'>Ended at <sup className='text-danger'>*</sup></label>
                            <input
                                type="date"
                                className='form-input'
                                name="endedAt"
                                value={values.endedAt}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                    </div>
                </div>
                <div className="form-check mb-3">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        name="isActive"
                        checked={values.isActive}
                        onChange={handleInputChange}
                        id="flexCheckDefault"
                    />
                    <label className="form-check-label" htmlFor="isActive">
                        Currently pursuing
                    </label>
                </div>
                <div className="form-group mb-3">
                    <button
                        type="submit"
                        className={`btn-main ${loading ? "bg-gray-400" : "bg-[#272343]"} text-light`}
                    // disabled={loading}
                    >
                        update
                        {/* {loading ? "updating..." : "update"} */}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default ExperienceUpdateModal;
