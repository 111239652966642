import React from 'react'
import { Search } from '../components'

const Home = () => {
  return (
    <>
      <div className="container-fluid">
        <div className="container">
          <div className="row py-4">
            <div className="col-lg">
              <h2 className='display-5 font-semibold'>Find your dream job</h2>
              <Search placehoder="search job by keywords, location, skills" />
            </div>
            <div className="col-lg">
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Home