import React from 'react'
import ReactQuill from 'react-quill';
import { TagsInput } from 'react-tag-input-component';

const ProfileFormOne = ({nextStep, handleChange,handleDescriptionChange, tags, setTags, setValues}) => {
  return (
    <>
            <div className="container-fluid">
                <div className="container-sm py-3">
                    <h4 className='text-primary text-2xl'>Add company basic details</h4>
                    <div className="row py-3">
                        <di className="col-lg-11">
                            <div className="row items-start justify-start mb-3">
                                <div className="col-3">
                                    <label className="text-[14px]" htmlFor="companyName">Company Name <sup className='text-danger'>*</sup></label>
                                </div>
                                <div className="col">
                                    <input type="text" name='companyName' onChange={handleChange('companyName')} className='form-input text-[14px] rounded-sm' placeholder='enter job title' />
                                </div>
                            </div>
                            <div className="row items-start justify-start mb-3">
                                <div className="col-3">
                                    <label className="text-[14px]" htmlFor="companyContact">Company Contact No <sup className='text-danger'>*</sup></label>
                                </div>
                                <div className="col">
                                    <input type="text" name='companyContact' onChange={handleChange('companyContact')} className='form-input text-[14px] rounded-sm' placeholder='enter job title' />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-3">
                                    <label htmlFor="companyEmail" className='text-[14px]'>Company E-mail <sup className='text-danger'>*</sup></label>
                                </div>
                                <div className="col">
                                    <input type="text" name='companyEmail' onChange={handleChange('companyEmail')} className='form-input text-[14px] rounded-sm' placeholder='enter job basic title' />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-3">
                                    <label htmlFor="companyWebsite" className='text-[14px]'>Company Website Link <sup className='text-danger'>*</sup></label>
                                </div>
                                <div className="col">
                                    <input type="text" name='companyWebsite' onChange={handleChange('companyWebsite')} className='form-input text-[14px] rounded-sm' placeholder='enter job basic title' />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-3">
                                    <label htmlFor="GST" className='text-[14px]'>Company GST No. <sup className='text-danger'>*</sup></label>
                                </div>
                                <div className="col">
                                    <input type="text" name='GST' onChange={handleChange('GST')} className='form-input text-[14px] rounded-sm' placeholder='enter job basic title' />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-3">
                                    <label htmlFor="service" className='text-[14px]'>Services <sup className='text-danger'>*</sup></label>
                                </div>
                                <div className="col">
                                    <TagsInput
                                        value={tags}
                                        onChange={(newSelected) => {
                                            setTags(newSelected);
                                            setValues((prevData) => ({
                                              ...prevData,
                                              service: newSelected, // update subCategory array
                                            }));
                                        }}
                                        name="service"
                                        placeHolder="press enter to add services"
                                    />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-3">
                                    <label htmlFor="description" className='text-[14px]'>Decsription <sup className='text-danger'>*</sup></label>
                                </div>
                                <div className="col mb-4">
                                    <ReactQuill
                                        id="description"
                                        name="description"
                                        theme="snow"
                                        modules={{
                                            toolbar: [
                                                ['bold', 'italic', 'underline'],
                                                [{ list: 'ordered' }, { list: 'bullet' }],
                                            ],
                                        }}
                                        style={{ height: '200px' }}
                                        onChange={handleDescriptionChange}
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <button onClick={nextStep} className='btn-main bg-[#272343] text-light'>Submit</button>
                            </div>
                        </di>
                    </div>
                </div>
            </div>
    </>
  )
}

export default ProfileFormOne