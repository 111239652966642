import React, { useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { useAdminContext, useJobContext } from "../../../context";
import { IoLocationSharp } from "react-icons/io5";
import DOMPurify from "dompurify";
import { FaSuitcase } from "react-icons/fa";

const JobCard = ({ data }) => {
  const { getAdminsDetailsById } = useAdminContext();
  const { getTimeDifference } = useJobContext();
  const [companyDataMap, setCompanyDataMap] = useState([]); // Initialize companyDataMap as an empty object
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCompanyData = async () => {
      const companyData = {};
      for (const item of data) {
        if (!companyData[item.sellerId]) {
          const company = await getAdminsDetailsById(item.sellerId, "Employer")
          companyData[item.sellerId] = company;
        }
      }
      setCompanyDataMap(companyData)

    }
    fetchCompanyData();
  }, [])


  return (
    <>
        {data?.map((job, idx) =>(
        <div className="card mb-3">
          <div className="row">
            <div className="col-md-3">
              <img src={job?.profilePicture} className="img-fluid rounded size-32" alt="..." />
            </div>
            <div className="col-lg flex justify-between flex-col py-2">
              <div className="header">
                <h5 className="card-title font-semibold">{job?.title}</h5>
                <ul className="nav flex-col gap-2">
                  <li className="capitalize text-[14px] flex items-center gap-3"><IoLocationSharp />{companyDataMap[job?.sellerId]?.data[0]?.city}</li>
                  <li className="capitalize text-[14px] flex items-center gap-3"><FaSuitcase />{job?.experience} required</li>
                </ul>
                <button className="btn-main"  onClick={() => navigate(`/view-jobs-detail/${job._id}`)}>View more details</button>
              </div>
              <div className="footer">
                <small className="text-body-secondary">
                  Posted on {getTimeDifference(job?.createdAt)}
                </small>
              </div>
            </div>
          </div>
        </div>
      ))}
      {/* {data?.map((items, idx) => (
        <div className="card mb-3" key={idx}>
          <div className="row">
            <div className="col-md-3">
              <img src={items.profilePicture} className="img-fluid rounded-start" alt="..." />
            </div>
            <div className="col-md-8">
              <div className="card-body">
                <p className="m-0 p-0 mb-2"><span className="m-0 p-0"></span></p>
                <div className="mb-4">
                  
                </div>
              </div>
              <p className="card-footer">
                
              </p>
            </div>
          </div>
        </div>
      ))} */}
    </>
  );
};

export default JobCard;
