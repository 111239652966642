import React from "react";
import { jobTypeSections } from "../../../testData/StaticData";
import {Link} from 'react-router-dom'
import { useJobContext, useSectionContext } from "../../../context";
import JobCard from "../../jobComponents/components/JobCard";
import { filterByLatestPost } from "../../../utils/filters/Filters";

const EmployeeHome = () => {
  const {sections} = useSectionContext();
  const {allJobs} = useJobContext();
  return (
    <>
      <nav className="overflow-x-scroll w-100 bg-[#8dc6ff]">
        <ul className="flex items-center category-nav gap-2 justify-evenly">
          {sections?.map((section, idx) => (
            <li key={idx} className="text-[14px] py-2 font-semibold">
              <Link to={`/view-jobs/${section._id}`}>{section.title}</Link>
            </li>
          ))}
        </ul>
      </nav>
      <div className="container-fluid min-h-[80vh] bg-cover">
        <div className="container">
          <div className="row py-4 items-center min-h-[80vh]">
            <div className="col-lg">
              <h1 className="display-4 font-bold uppercase text-[#272343]">Find your career With RESO ...</h1>
              <div className="form-group py-4 flex items-center">
                <input type="text" className="bg-slate-200 w-[70%] py-3 px-3 outline-none border-none" placeholder="search jobs with keywords, roles, and locations" />
                <button className="outline-none border-none bg-[#272343] text-white py-3 px-3">Search jobs</button>
              </div>
            </div>
            <div className="col-lg">
              <img src="https://shorturl.at/lKNQV" alt="" className="img-fluid rounded" />
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid bg-blue-200 py-4">
        <div className="container">
          <h4 className="text-2xl text-center">Jobs for you</h4>
          <div className="row justify-center gap-1 py-4">
            <div className="col-lg-10 overflow-hidden flex gap-2">
              {jobTypeSections.map((area, idx) => (
                <div className="bg-light flex flex-col justify-center items-center gap-2 py-3 w-52">
                  <span className="text-2xl bg-teal-600 p-3 text-light rounded-md " >{area.icon}</span>
                  <span className="text-[14px]">{area.title}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid py-4">
        <div className="container">
          <h4 className="text-2xl text-center font-semibold">Latest Jobs</h4>
          <div className="mt-4 flex flex-wrap justify-center gap-2">
            <JobCard data={filterByLatestPost(allJobs)} />
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployeeHome;
