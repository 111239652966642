import React, { createContext, useEffect, useState } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import { createEmployeeAddressDetails, 
  createEmployeeEducationDetails, 
  createEmployeeExperienceDetails, 
  createEmployeeInfoDetails, 
  deleteEmployeeEducationDetails, 
  deleteEmployeeExperienceDetails, 
  fetchEmployeeApplicationById, 
  updateEmployeeAddressDetails, 
  updateEmployeeEducationDetails, 
  updateEmployeeExperienceDetails, 
  updateEmployeeInfoDetails} from '../../api/Api';

export const EmployeeContext = createContext();

const EmployeeContextProvider = ({ children }) => {
    const userId = JSON.parse(sessionStorage.getItem('data'));

    const formatDate = (dateString) => {
      const date = new Date(dateString);
      const options = { day: '2-digit', month: 'long', year: 'numeric' };
      const formattedDate = date.toLocaleDateString('en-US', options);
      return formattedDate;
    }
    const addEducationDetails = async(data) => {
      try {
        const response = await axios.post(createEmployeeEducationDetails, data);
        console.log("added edu", response)
        toast.success("job added successfully")
        return response;
      } catch (error) {
        console.log("from addEducationDetails()", error)
      }
    }

    const addExperienceDetails = async(data) => {
      try {
        const response = await axios.post(createEmployeeExperienceDetails, data);
        console.log("added edu", response)
        toast.success("Added information successfully")
        return response;
      } catch (error) {
        console.log("from addEducationDetails()", error)
      }
    }
    const addAddressDetails = async(data) => {
      try {
        const response = await axios.post(createEmployeeAddressDetails, data);
        console.log("added edu", response)
        toast.success("Added information successfully")
        return response;
      } catch (error) {
        console.log("from addEducationDetails()", error)
      }
    }
    const addInfoDetails = async(data) => {
      try {
        const response = await axios.post(createEmployeeInfoDetails, data);
        console.log("added edu", response)
        toast.success("Added information successfully")
        return response;
      } catch (error) {
        console.log("from addEducationDetails()", error)
      }
    }
    const getEmployeeProfileDetails = async() => {
        try {
            const response = await axios.get(`${fetchEmployeeApplicationById}/${userId._id}`);
            return response;
        } catch (error) {
            console.log("error from getEmployeeProfileDetails()")
        }
    }

    const getEmployeeEducationDetailsById = async() => {
      try {
          const response = await axios.get(`${fetchEmployeeApplicationById}/${userId._id}`);
          return response;
      } catch (error) {
          console.log("error from getEmployeeProfileDetails()")
      }
  }

    const deleteEducationDetails = async(id) => {
      try {
          const response = await axios.delete(`${deleteEmployeeEducationDetails}/${id}`);
          toast.success("deleted successfully")
          return response;
      } catch (error) {
          console.log("error from getEmployeeProfileDetails()")
      }
    }

    const deleteExperienceDetails = async(id) => {
      try {
          const response = await axios.delete(`${deleteEmployeeExperienceDetails}/${id}`);
          toast.success("deleted successfully")
          return response;
      } catch (error) {
          console.log("error from getEmployeeProfileDetails()")
      }
    }

    const updateEducationDetails = async(id, data) => {
      try {
          const response = await axios.put(`${updateEmployeeEducationDetails}/${id}`, data);
          toast.success("updated successfully")
          return response;
      } catch (error) {
          console.log("error from getEmployeeProfileDetails()")
      }
    }

    const updateExperienceDetails = async(id, data) => {
      try {
          const response = await axios.put(`${updateEmployeeExperienceDetails}/${id}`, data);
          toast.success("updated successfully")
          return response;
      } catch (error) {
          console.log("error from getEmployeeProfileDetails()")
      }
    }

    const updateProfileInfoDetails = async(id, data) => {
      try {
          const response = await axios.put(`${updateEmployeeInfoDetails}/${id}`, data);
          toast.success("updated successfully")
          return response;
      } catch (error) {
          console.log("error from getEmployeeProfileDetails()")
      }
    }
    const updateProfileAddressDetails = async(id, data) => {
      try {
          const response = await axios.put(`${updateEmployeeAddressDetails}/${id}`, data);
          toast.success("updated successfully")
          return response;
      } catch (error) {
          console.log("error from getEmployeeProfileDetails()")
      }
    }

  return (
    <EmployeeContext.Provider value={{ 
      getEmployeeProfileDetails,
      deleteEducationDetails,
      formatDate,
      addEducationDetails,
      addExperienceDetails,
      addAddressDetails,
      deleteExperienceDetails,
      addInfoDetails,
      updateEducationDetails,
      updateExperienceDetails,
      updateProfileInfoDetails,
      updateProfileAddressDetails
       }}>
      {children}
    </EmployeeContext.Provider>
  );
};

export default EmployeeContextProvider;
