import React, { createContext, useEffect, useId, useState } from 'react';
import axios from 'axios';
import { addAdminProfile, createJobApplicationAPI, fetchAdminProfile, fetchEmployeeApplicationById, fetchJobApplicationBySellerIdAPI, fetchJobApplicationByUserIdAPI } from '../../api/Api';
import toast from 'react-hot-toast';

export const AdminContext = createContext();

const AdminContextProvider = ({ children }) => {
  const user = JSON.parse(sessionStorage.getItem('data'));

    const addAdminProfleDetail = async(data) => {
      try {
        const response = await axios.post(addAdminProfile, data);
        toast.success("profile updated successfully")
        window.location.href = "/view-profile"
        return response;
      } catch (error) {
        
      }
    }
    const getAdminsDetailsById = async (sellerId, role) => {
      try {
        const response = await axios.get(`${fetchAdminProfile}/${sellerId}`, {
            headers : {
                'user-role' : role
            }
        });
        return response;
      } catch (error) {
        console.log(error);
      }
    };

    const createJobApplication = async (data) => {
      try {
        const response = await axios.post(createJobApplicationAPI, data);
        toast.success("Applied successfully");
        console.log("Application submitted", response);
        window.location.href = "/view-my-applications"
        return response;
      } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
          // Check for 409 status code specifically
          if (error.response.status === 409) {
            toast.error("You have already applied for this job. Please wait for response ");
            return; // Return early to avoid re-throwing the error
          }
          // Handle other API errors
          toast.error(error.response.data?.message || "Failed to apply for the job. Please try again.");
        } else {
          // Handle non-Axios errors
          console.error("Error creating job application:", error);
          toast.error("An unexpected error occurred. Please try again.");
          throw error; // Re-throw the error if needed elsewhere
        }
      }
    };

    const getApplicationByUserId = async () => {
      try {
        const response = await axios.get(`${fetchJobApplicationByUserIdAPI}/${user._id}`);
        return response;
      } catch (error) {
        console.error("Error fetching job application by user ID:", error);
        // toast.error("Failed to fetch job application. Please try again.");
        throw error; // Re-throw the error if needed elsewhere
      }
    };
  
    const getApplicationBySellerId = async () => {
      try {
        const response = await axios.get(`${fetchJobApplicationBySellerIdAPI}/${user._id}`);
        return response;
      } catch (error) {
        console.error("Error fetching job application by user ID:", error);
        // toast.error("Failed to fetch job application. Please try again.");
        throw error; // Re-throw the error if needed elsewhere
      }
    };

    const getCandidateDetails = async(id) => {
      try {
          const response = await axios.get(`${fetchEmployeeApplicationById}/${id}`);
          return response;
      } catch (error) {
          console.log("error from getEmployeeProfileDetails()")
      }
  }

    useEffect(() => {
      getApplicationByUserId().catch(error => console.error("Error in useEffect:", error));
      getApplicationBySellerId().catch(error => console.error("Error in useEffect:", error));
    }, []);
  return (
    <AdminContext.Provider value={{
      getAdminsDetailsById, 
      addAdminProfleDetail, 
      createJobApplication,
      getApplicationByUserId,
      getApplicationBySellerId,
      getCandidateDetails
      }}>
      {children}
    </AdminContext.Provider>
  )
}

export default AdminContextProvider;