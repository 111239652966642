import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ModalBox from "../modal/ModalBox";
import { Login } from "../../shared";

const Navbar = () => {
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();
  const handleModalClose = () => {
    setOpenModal(false)
}

  return (
    <>
      <div className="bg-slate-100">
        <nav className="mx-8 flex justify-between items-center py-2">
          <Link className="font-bold text-xl">RESO</Link>
          <ul className="flex items-center gap-3">
            <li>
              <Link className="text-[14px]">About us</Link>
            </li>
            <li>
              <button className="btn-main bg-[#272343] text-white" onClick={() => navigate("/login")}>log in</button>
            </li>
          </ul>
        </nav>
      </div>
      {openModal ? 
        <ModalBox>
          <Login handleModalClose={handleModalClose} />
        </ModalBox> 
      : null}
    </>
  );
};

export default Navbar;
