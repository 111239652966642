import React, { useState } from 'react'
import AddJobForm_1 from './components/AddJobForm_1';
import AddJobForm_2 from './components/AddJobForm_2';
import { useJobContext } from '../../context';

const AddJobForm = () => {
    const { addJobData } = useJobContext();
    const userId = JSON.parse(sessionStorage.getItem('data'));
    const [selected, setSelected] = useState([]);
    const [tags, setTags] = useState([]);

    const [values, setValues] = useState({
        // emailId : userId.email,
        sellerId: userId._id,
        sortId: userId._id,
        title: '',
        basicTitle: '',
        category: '',
        subCategory: [],
        shortDescription: '',
        description: '',
        tags: [],
        profilePicture: '',
        maxValue : '',
        jobType: '',
        workType: '',
        qualification: '',
        experience: '',
        salaryRange: '',
    });
    const [step, setStep] = useState(1);

    const nextStep = () => {
        setStep(step + 1);
    }

    const prevStep = () => {
        setStep(step - 1);
    }

    const handleChange = (input) => (e) => {
        setValues({ ...values, [input]: e.target.value })
    }

    const handleQuillChange = (name, value) => {
        setValues((prevData) => ({
          ...values,
          [name]: value,
        }));
      }
      const handleSubmit = (e) => {
        e.preventDefault();
        console.log("this is job data", values)
        addJobData(values);
      };
      const handleDescriptionChange = (value, delta, source, editor) => {
        handleQuillChange('description', value);
      }
    
      const handleShortDescriptionChange = (value, delta, source, editor) => {
        handleQuillChange('shortDescription', value);
      }

    switch (step) {
        case 1:
            return (
                <AddJobForm_1
                    nextStep={nextStep} 
                    handleChange= {handleChange} 
                    selected={selected} 
                    setSelected={setSelected}
                    values={values}
                    setValues={setValues}
                    setTags={setTags}
                    tags={tags}
                    handleDescriptionChange={handleDescriptionChange}
                    handleShortDescriptionChange={handleShortDescriptionChange}
                />
            );
        case 2:
            return (
                <AddJobForm_2
                    nextStep={nextStep} 
                    prevStep={prevStep}
                    handleChange={handleChange}
                    handleSubmit={handleSubmit}
                />
            );
        default : 
            return (
                <AddJobForm_1 />
            )
    }
}

export default AddJobForm