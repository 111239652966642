import React from 'react'
import { Link } from 'react-router-dom'
import AddressForm from './AddressForm'
import EducationForm from './EducationForm'
import ExperienceForm from './ExperienceForm'
import ProfessionalDetailsForm from './ProfessionalDetailsForm'

const BasicDetailsForm = () => {
    const userData = JSON.parse(sessionStorage.getItem('data')) 
  return (
    <>
        <div className="row mt-3">
            <h4 className='heading_4'>Basic Information</h4>
            <div className="col-lg py-3">
                <div className="form-group">
                    <div className="row">
                        <div className="col-3">
                            <h6 className='h6 text-secondary'>Name</h6>
                        </div>
                        <div className="col">
                            <span className='capitalize'>{userData?.firstName} {userData?.lastName}</span>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-3">
                            <h6 className='h6 text-secondary'>Mobile number</h6>
                        </div>
                        <div className="col">
                            <span className=''>{userData?.mobile}</span>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col-3">
                            <h6 className='h6 text-secondary'>Email address</h6>
                        </div>
                        <div className="col">
                            <span className=''>{userData?.email}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ProfessionalDetailsForm />
        <AddressForm />
        <EducationForm />
        <ExperienceForm />
    </>
  )
}

export default BasicDetailsForm