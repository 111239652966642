import React, { useState } from "react";
import FormStepOne from "./FormStepOne";
import FormStepTwo from "./FormStepTwo";
import { useAuthContext } from "../../../context";
import { Link, useNavigate } from "react-router-dom";

const Signup = () => {
  const [step, setStep] = useState(1);
  const { RegisterUser } = useAuthContext();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    password: "",
    role: "",
  });

  const nextStep = () => setStep((prevStep) => prevStep + 1);
  const prevStep = () => setStep((prevStep) => prevStep - 1);

  const handleInputData = (input) => (e) => {
    const { value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [input]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    RegisterUser(formData);
    navigate('/login')
  };

  return (
    <div className="container-fluid">
      <div className="container-sm">
        <div className="row py-2">
          <div className="col-lg"></div>
          <div className="col-lg-6">
            <h4 className="font-semibold fs-2 text-[#070f4e]">Register your account</h4>
            <Link className="nav-link text-[14px] mb-3">Already have an account ? <span className="text-primary">Login here</span></Link>
            {step === 1 && (
              <FormStepOne
                nextStep={nextStep}
                handleInputChange={handleInputData}
                formData={formData}
              />
            )}
            {step === 2 && (
              <FormStepTwo
                nextStep={nextStep}
                prevStep={prevStep}
                handleInputChange={handleInputData}
                handleSubmit={handleSubmit}
                formData={formData}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
