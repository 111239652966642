import React, { useEffect, useState } from 'react';
import { useEmployeeContext } from '../../../../context';
import { Link } from 'react-router-dom';
import { ModalBox } from '../../../../components';
import AddressModal from '../profileModals/AddressModal';
import AddressUpdateModal from '../profileUpdateModals/AddressUpdateModal';

const AddressForm = () => {
    const { getEmployeeProfileDetails } = useEmployeeContext();
    const [addressDetail, setAddressDetail] = useState(null); // Initialize as null
    const [openModal, setOpenModal] = useState(false);
    const [openUpdateModal, setOpenUpdateModal] = useState(false);
    const [currentAddressDetail, setCurrentAddressDetail] = useState(null);

    const handleEditClick = (addressDetail) => {
        setCurrentAddressDetail(addressDetail);
        setOpenUpdateModal(true);
        
    };

    const handleModalClose = () => {
        setOpenModal(false)
        setOpenUpdateModal(false)
        setCurrentAddressDetail(null)
    }

    useEffect(() => {
        const fetchAddressDetails = async () => { 
            try {
                const result = await getEmployeeProfileDetails(); // Call the function to get the result
                setAddressDetail(result.data.address[0]); // Assuming result.data contains the address details
            } catch (error) {
                console.error("Error fetching address details:", error);
                // Handle error
            }
        }

        fetchAddressDetails();
    }, [getEmployeeProfileDetails]); // Add getEmployeeProfileDetails to dependency array
    return (
        <div className="row mt-3 border py-3 rounded">
            <div className="group-box flex items-center justify-between">
                <h4 className='heading_4'>Address Information</h4>
                {/* <button className='btn-main bg-[#8dc6ff] text-dark'>edit</button> */}
                <button onClick={() => handleEditClick(addressDetail)} className='btn-main bg-[#272343] text-light'>change address</button>
            </div>
            <div className="col-lg py-3">
                {addressDetail ? (
                    <div className="form-group">
                        <div className="row">
                            <div className="col-3">
                                <h6 className='h6 text-secondary'>Location</h6>
                            </div>
                            <div className="col">
                                <span className=''>{addressDetail?.locality}</span>
                                <p>{addressDetail?.city}, {addressDetail?.state},{addressDetail?.pincode}, {addressDetail?.country}</p>
                            </div>
                        </div>
                    </div>
                ) : (
                    <Link onClick={() => setOpenModal(true)} className='text-primary nav-link'>Click to add details</Link>
                )}
             </div>
            {openModal ?<ModalBox>
                <AddressModal handleModalClose={handleModalClose} />
            </ModalBox> : null}
            {openUpdateModal && (
                <ModalBox>
                    <AddressUpdateModal handleModalClose={handleModalClose} currentAddressDetail={currentAddressDetail} />
                </ModalBox>
            )}
        </div>
    );
}

export default AddressForm;
