import React from 'react'

const ProfileFormTwo = ({prevStep, handleChange,handleSubmit}) => {
    return (
        <>
            <div className="container-fluid">
                <div className="container-sm py-3">
                    <h4 className='text-primary text-2xl'>Add company basic details</h4>
                    <div className="row py-3">
                        <di className="col-lg-11">
                            <div className="row items-start justify-start mb-3">
                                <div className="col-3">
                                    <label className="text-[14px]" htmlFor="numberOfEmployee">Number of Employees <sup className='text-danger'>*</sup></label>
                                </div>
                                <div className="col">
                                    <input type="text" name='companyName' onChange={handleChange('numberOfEmployee')} className='form-input text-[14px] rounded-sm' placeholder='enter job title' />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-3">
                                    <label htmlFor="country" className='text-[14px]'>Country <sup className='text-danger'>*</sup></label>
                                </div>
                                <div className="col">
                                    <input type="text" name='country' onChange={handleChange('country')} className='form-input text-[14px] rounded-sm' placeholder='enter job basic title' />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-3">
                                    <label htmlFor="state" className='text-[14px]'>State. <sup className='text-danger'>*</sup></label>
                                </div>
                                <div className="col">
                                    <input type="text" name='companyGST' onChange={handleChange('state')} className='form-input text-[14px] rounded-sm' placeholder='enter job basic title' />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-3">
                                    <label htmlFor="city" className='text-[14px]'>City <sup className='text-danger'>*</sup></label>
                                </div>
                                <div className="col">
                                    <input type="text" name='city' onChange={handleChange('city')} className='form-input text-[14px] rounded-sm' placeholder='enter job basic title' />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-3">
                                    <label htmlFor="pincode" className='text-[14px]'>Pincode <sup className='text-danger'>*</sup></label>
                                </div>
                                <div className="col">
                                    <input type="text" name='pincode' onChange={handleChange('pincode')} className='form-input text-[14px] rounded-sm' placeholder='enter job basic title' />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-3">
                                    <label htmlFor="companyAddress" className='text-[14px]'>Company Address <sup className='text-danger'>*</sup></label>
                                </div>
                                <div className="col">
                                    <input type="text" name='companyAddress' onChange={handleChange('companyAddress')} className='form-input text-[14px] rounded-sm' placeholder='enter job basic title' />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-3">
                                    <label htmlFor="startedAt" className='text-[14px]'>Company Start from <sup className='text-danger'>*</sup></label>
                                </div>
                                <div className="col">
                                    <input type="date" name="startedAt" className='form-input' onChange={handleChange('startedAt')}/>
                                </div>
                            </div>
                            <div className="form-group flex items-center gap-3">
                                <button onClick={handleSubmit} className='btn-main bg-[#272343] text-light'>Submit</button>
                                <button onClick={prevStep} className='btn-main'>Back</button>
                            </div>
                        </di>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProfileFormTwo