import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { FaRegEdit } from "react-icons/fa";
import { useAdminContext, useEmployeeContext } from '../../../context';
import DOMPurify from 'dompurify';

const EmployerProfile = () => {
    const { getAdminsDetailsById } = useAdminContext();
    const {formatDate} = useEmployeeContext();
    const [adminProfile, setAdminProfile] = useState({});
    const userId = JSON.parse(sessionStorage.getItem('data'));
    const navigate = useNavigate();

    useEffect(() => {
        const getProfile = async () => {
            try {
                const response = await getAdminsDetailsById(userId._id, userId.role);
                setAdminProfile(response.data[0]);
            } catch (error) {
                console.error("Error fetching profile:", error);
            }
        };
        getProfile();
    }, [getAdminsDetailsById, userId._id, userId.role]);

    // Loading state
    if (!adminProfile) {
        return (
            <div className='container-fluid min-h-[100vh] bg-slate-700 flex items-center justify-center'>
                <div className="col-lg-6 py-4 px-2 bg-light rounded flex flex-col items-center justify-center gap-2">
                    <h3 className='text-3xl font-bold text-danger'>OOPS ! No Data Found</h3>
                    <span className='text-semibold text-[14px] text-gray-500'>Add company details to start adding jobs</span>
                    <button onClick={() => navigate('/update-profile')} className='btn-main bg-[#272343] text-light'>Add Data</button>
                </div>
            </div>
        );
    }

    return (
        <>
            <div className="container-fluid">
                <div className="container-sm">
                    <div className="row py-3">
                        <h4 className='text-2xl font-semibold'>Company profile details</h4>
                        <div className="col-lg-3 py-3">
                            <div className="col">
                                <img src="https://avatar.iran.liara.run/public" alt="" className='img-thumbnail rounded-circle' />
                                <Link className='text-blue-500 flex items-center gap-2'>Edit company profile picture <FaRegEdit /> </Link>
                            </div>
                        </div>
                    </div>
                    <div className="row border py-3 mb-3">
                        <div className="col-lg-8">
                            <div className="card">
                                <div className="card-header">
                                    <div className='col'>
                                        Basic Details
                                    </div>
                                    {/* <div className="col">
                                    <button></button>
                                </div> */}
                                </div>
                                <div className="card-body">
                                    <p className='text-[14px] capitalize text-secondary'><b>Name</b> : {userId?.firstName} {userId?.lastName}</p>
                                    <p className='text-[14px] capitalize text-secondary'><b>Email</b> : {userId?.email}</p>
                                    <p className='text-[14px] capitalize text-secondary'><b>Contact No.</b> : {userId?.firstName} {userId?.lastName}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg">
                            <div className="card">
                                <div className="card-header flex items-center justify-between">
                                    <div className='box'>
                                        Company Details
                                    </div>
                                    <div className="box">
                                        <button className='btn-main'>edit</button>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <p className='text-[14px] capitalize text-secondary'><b>Company Name</b> : {adminProfile?.companyName}</p>
                                    <p className='text-[14px] capitalize text-secondary'><b>Company Email</b> : {adminProfile?.companyEmail}</p>
                                    <p className='text-[14px] capitalize text-secondary'><b>Company Landline.</b> : {adminProfile?.companyContact}</p>
                                    <p className='text-[14px] capitalize text-secondary'><b>Company Address.</b> : {adminProfile?.companyAddress}, {adminProfile?.city}, {adminProfile?.state}, {adminProfile?.pincode}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-3 border py-3">
                        <div className="col-lg">
                            <div className="card">
                                <div className="card-header">
                                    <p>Company Information</p>
                                </div>
                                <div className="card-body">
                                    <p className="card-title">About {adminProfile?.companyName}</p>
                                    <p><span className="text-[14px]" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(adminProfile?.description) }} /></p>
                                    <div className="col-lg-6">
                                        <table className='table mt-4 text-[14px]'>
                                            <thead>
                                                <tr>
                                                    <th>No of Employees</th>
                                                    <td>{adminProfile?.numberOfEmployee}</td>
                                                </tr>
                                                <tr>
                                                    <th>Services</th>
                                                    <td className='flex items-center gap-2'>{adminProfile?.service?.map((service, idx)=>(
                                                        <span className='px-2 bg-slate-100 uppercase'>{service}</span>
                                                        ))}
                                                    </td>
                                                </tr>
                                            </thead>
                                        </table>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <p className='text-[14px]'><b>Company Started on :</b> {formatDate(adminProfile?.startedAt)}</p>
                                    <a className='text-[14px] text-primary' href={`https://${adminProfile?.companyWebsite}`} target='_blank'><b>Website</b> {adminProfile?.companyWebsite}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EmployerProfile