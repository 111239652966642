import React, { useEffect, useState } from 'react'
import {useJobContext, useSectionContext} from '../../../context'
import { confirmAlert } from 'react-confirm-alert';

const ViewJob = () => {
  const {Jobs, getTimeDifference, deleteJobById} = useJobContext();
  const {getJobSectionById} = useSectionContext();
  const [categories, setCategories] = useState([]);
  const [error, setError] = useState(null);

  const fetchCategories = async () => {
    try {
      const categoryIds = Jobs.map((job) => job.category);
      const fetchedCategories = await Promise.all(categoryIds.map((categoryId) => getJobSectionById(categoryId)));
      setCategories(fetchedCategories);
    } catch (error) {
      setError(error.message);
    }
  };

  const handleLogOut = (id) => {
    return () => {
      confirmAlert({
        title: 'Confirm to delete',
        message: 'Are you sure to do this.',
        buttons: [
          {
            label: 'Yes',
            onClick: () => deleteJobById(id)
          },
          {
            label: 'No',
            onClick: () => confirmAlert({})
          }
        ]
      });
    }
  }

  useEffect(() => {
    fetchCategories();
  }, [Jobs, getJobSectionById]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <>
      <div className="container-fluid">
        <div className="container">
          <table className='table text-[14px]'>
            <thead>
              <tr>
                <th><input type="checkbox" name="" id="" /> Select all</th>
                <th>Title</th>
                <th>Category</th>
                <th>Open position</th>
                <th>Created at</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {Jobs?.map((job, idx) => (
                <tr key={idx}>
                  <td><input type="checkbox" name="" id="" /></td>
                  <td>{job.title}</td>
                  <td>{categories[idx] ? categories[idx][0]?.title || 'Unknown' : 'Unknown'}</td>
                  <td>{job.maxValue}</td>
                  <td>{getTimeDifference(job.createdAt)}</td>
                  <td className='flex items-center gap-3'>
                    <button onClick={handleLogOut(job._id)} className='btn-main bg-[#272343] text-light'>delete</button>
                    <button className='btn-main bg-red-500 text-white'>pause</button>
                    <button className='btn-main bg-blue-500 text-white'>Edit</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

export default ViewJob