import React from 'react';

const AddJobForm_2 = ({ nextStep, prevStep, handleSubmit, handleChange }) => {
    return (
        <>
            <div className="container-fluid">
                <div className="container-sm py-3">
                    <h4 className='text-primary'>Add company basic detail</h4>
                    <div className="row py-3">
                        <div className="col-lg-11">
                            <div className="row mb-3">
                                <div className="col-3">
                                    <label htmlFor="profilePicture" className='text-[14px]'>Company logo<sup className='text-danger'>*</sup></label>
                                </div>
                                <div className="col">
                                    <input type="text" name='profilePicture' onChange={handleChange('profilePicture')} placeholder='company logo or profile image' className='form-input' />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-3">
                                    <label htmlFor="maxValue" className='text-[14px]'>Total openings<sup className='text-danger'>*</sup></label>
                                </div>
                                <div className="col">
                                    <input type="number" name='maxValue' onChange={handleChange('maxValue')} className='form-input' placeholder='number of openings' />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-3">
                                    <label htmlFor="experience" className='text-[14px]'>Min experience<sup className='text-danger'>*</sup></label>
                                </div>
                                <div className="col">
                                    <select className='form-input' onChange={handleChange('experience')}>
                                        <option value="">choose min experience</option>
                                        <option value="Fresher">Fresher</option>
                                        <option value="1 - 2 years">1 - 2 years</option>
                                        <option value="2 - 3 years">2 - 3 years</option>
                                        <option value="4 - 6 years">4 - 6 years</option>
                                        <option value="7 - 8 years">7 - 8 years</option>
                                    </select>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-3">
                                    <label htmlFor="salaryRange" className='text-[14px]'>Salary range<sup className='text-danger'>*</sup></label>
                                </div>
                                <div className="col">
                                    <select className='form-input' onChange={handleChange('salaryRange')}>
                                        <option value="">choose salary range</option>
                                        <option value="5k - 10k">5k - 10k</option>
                                        <option value="15k - 25k">15k - 25k</option>
                                        <option value="4 lpa - 6 lpa">4 lpa - 6 lpa</option>
                                        <option value="8 lpa - 10 lpa">8 lpa - 10 lpa</option>
                                        <option value="Not disclosed">Not disclosed</option>
                                    </select>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-3">
                                    <label htmlFor="jobType" className='text-[14px]'>Job type<sup className='text-danger'>*</sup></label>
                                </div>
                                <div className="col">
                                    <select className='form-input' onChange={handleChange('jobType')}>
                                        <option value="">choose job type</option>
                                        <option value="Work from home">Work from home</option>
                                        <option value="Work from office">Work from office</option>
                                        <option value="Hybrid">Hybrid</option>
                                    </select>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-3">
                                    <label htmlFor="workType" className='text-[14px]'>Work type<sup className='text-danger'>*</sup></label>
                                </div>
                                <div className="col">
                                    <select className='form-input' onChange={handleChange('workType')}>
                                        <option value="">choose work type</option>
                                        <option value="Full-time">Full-time</option>
                                        <option value="Part-time">Part-time</option>
                                        <option value="Internship">Internship</option>
                                        <option value="Contractual">Contractual</option>
                                    </select>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-3">
                                    <label htmlFor="qualification" className='text-[14px]'>Minimum qualification<sup className='text-danger'>*</sup></label>
                                </div>
                                <div className="col">
                                    <select className='form-input' onChange={handleChange('qualification')}> 
                                        <option value="">choose min qualification</option>
                                        <option value="Post-graduation">Post-graduation</option>
                                        <option value="Graduation">Graduation</option>
                                        <option value="Diploma">Diploma</option>
                                        <option value="10th/12th Passed">10th/12th Passed</option>
                                        <option value="Any">Any</option>
                                    </select>
                                </div>
                            </div>
                            <div className="form-group mt-4 d-flex gap-3 py-3">
                                <button onClick={handleSubmit} className='btn-main bg-[#070f4e] text-white'>Submit</button>
                                <button onClick={prevStep} className='btn-main'>Back</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AddJobForm_2;
