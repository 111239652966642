import { AllJobs, JobDetailPage } from "../pages";
import { Login, Signup } from "../shared";

export const authRoutes = [
    {path : "/login", element : <Login />},
    {path : "/register", element : <Signup />},
]

export const employeeContentRoutes = [
    {
        path : "/view-jobs",
        element : <AllJobs />
    },
    {
        path : "/view-jobs/:category",
        element : <AllJobs />
    },
    {
        path : "/view-jobs-detail/:id",
        element : <JobDetailPage />
    },
]