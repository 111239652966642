import { createContext, useState } from "react";
import { loginUserURL, registerUserURL } from "../../api/Api";
import axios from 'axios';
import { toast } from "react-toastify";

export const AuthContext = createContext();

const AuthContextProvider = ({ children }) => {
  const [token, setToken] = useState(sessionStorage.getItem('token') || '');
  const [role, setRole] = useState(sessionStorage.getItem('role') || '');

  const AuthenticateUser = async (credentials) => {
    try {
      const response = await axios.post(loginUserURL, credentials);
      setToken(response.data.authResponse.token);
      setRole(response.data.authResponse.data.role);
      sessionStorage.setItem('token', response.data.authResponse.token);
      sessionStorage.setItem('role', response.data.authResponse.data.role);
      sessionStorage.setItem('data', JSON.stringify(response.data.authResponse.data));
      toast.success(response.data.authResponse.message);
      console.log(response.data.authResponse); // Assuming the response contains data to log
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log("Authentication failed: ", error.message);
        toast.error("Authentication failed");
      } else {
        console.log("Unexpected error: ", error.message);
        toast.error("Unexpected error");
      }
    }
  };

  const RegisterUser = async (credentials) => {
    try {
      const response = await axios.post(registerUserURL, credentials);
      toast.success("success")
      window.location.href = "/login"
      return response
    } catch (error) {
      
    }
  }
  const logoutUser = () => {
    sessionStorage.clear();
    toast.success("log out successfully");
    window.location.href = "/"
  }

  return (
    <AuthContext.Provider value={{ AuthenticateUser, token, role, logoutUser, RegisterUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
