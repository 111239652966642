import { CategorySectionTab, JobTab, ViewCategory } from "../features";
import { EmployeeApplied, EmployeeProfile, EmployerProfileForm } from "../shared";
import { EmployerProfile } from "../shared/employerPages";

export const employerRoutes = [
    {
        path : '/add-category',
        element : <CategorySectionTab />   
    },
    {
        path : '/add-job',
        element : <JobTab />   
    },
    {
        path : '/view-job',
        element : <ViewCategory />   
    },
    {
        path : '/view-profile',
        element : <EmployerProfile />   
    },
    {
        path : '/update-profile',
        element : <EmployerProfileForm />   
    },
]

export const employeeRoutes = [
    {path : '/view-profile', element : <EmployeeProfile />},
    {path : '/view-my-applications', element : <EmployeeApplied />}
]