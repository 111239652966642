import React from 'react'
import { TagsInput } from "react-tag-input-component";
import { useSectionContext } from '../../../context';
import ReactQuill from 'react-quill'


const AddJobForm_1 = ({ nextStep, handleChange,handleShortDescriptionChange, handleDescriptionChange, values, selected, setSelected,setValues,setTags, tags }) => {
    const { sections } = useSectionContext();

    return (
        <>
            <div className="container-fluid">
                <div className="container-sm py-3">
                    <h4 className='text-primary'>Add job basic details</h4>
                    <div className="row py-3">
                        <di className="col-lg-11">
                            <div className="row items-start justify-start mb-3">
                                <div className="col-3">
                                    <label className="text-[14px]" htmlFor="title">Job title <sup className='text-danger'>*</sup></label>
                                </div>
                                <div className="col">
                                    <input type="text" name='title' onChange={handleChange('title')} className='form-input text-[14px] rounded-sm' placeholder='enter job title' />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-3">
                                    <label htmlFor="basicTitle" className='text-[14px]'>Job basic title <sup className='text-danger'>*</sup></label>
                                </div>
                                <div className="col">
                                    <input type="text" name='basicTitle' onChange={handleChange('basicTitle')} className='form-input text-[14px] rounded-sm' placeholder='enter job basic title' />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-3">
                                    <label htmlFor="category" className='text-[14px]'>Job category <sup className='text-danger'>*</sup></label>
                                </div>
                                <div className="col">
                                    <select className="form-input" onChange={handleChange('category')}>
                                        <option defaultValue='choose an option'>choose category</option>
                                        {sections?.map((item, index) => (
                                            <option key={index} value={item._id}>{item.title}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-3">
                                    <label htmlFor="subCategory" className='text-[14px]'>Required skills <sup className='text-danger'>*</sup></label>
                                </div>
                                <div className="col">
                                    <TagsInput
                                        value={selected}
                                        onChange={(newSelected) => {
                                            setSelected(newSelected);
                                            setValues((prevData) => ({
                                              ...prevData,
                                              subCategory: newSelected, // update subCategory array
                                            }));
                                        }}
                                        name="subCategory"
                                        placeHolder="press enter to add tags"
                                    />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-3">
                                    <label htmlFor="subCategory" className='text-[14px]'>Tags <sup className='text-danger'>*</sup></label>
                                </div>
                                <div className="col">
                                    <TagsInput
                                        value={tags}
                                        onChange={(newSelected) => {
                                            setTags(newSelected);
                                            setValues((prevData) => ({
                                              ...prevData,
                                              tags: newSelected, // update subCategory array
                                            }));
                                        }}
                                        name="subCategory"
                                        placeHolder="press enter to add tags"
                                    />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-3">
                                    <label htmlFor="description" className='text-[14px]'>Job decsription <sup className='text-danger'>*</sup></label>
                                </div>
                                <div className="col mb-4">
                                    <ReactQuill
                                        id="description"
                                        name="description"
                                        theme="snow"
                                        modules={{
                                            toolbar: [
                                                ['bold', 'italic', 'underline'],
                                                [{ list: 'ordered' }, { list: 'bullet' }],
                                            ],
                                        }}
                                        style={{ height: '200px' }}
                                        onChange={handleDescriptionChange}
                                    />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-3">
                                    <label htmlFor="shortDescription" className='text-[14px]'>Job short decsription <sup className='text-danger'>*</sup></label>
                                </div>
                                <div className="col position-relative mt-4">
                                    <ReactQuill
                                        id="shortDescription"
                                        name="shortDescription"
                                        theme="snow"
                                        modules={{
                                            toolbar: [
                                                ['bold', 'italic', 'underline'],
                                                [{ list: 'ordered' }, { list: 'bullet' }],
                                            ],
                                        }}
                                        style={{ height: '200px' }}
                                        onChange={handleShortDescriptionChange}
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <button onClick={nextStep} className='btn-main bg-[#272343] text-light'>Submit</button>
                            </div>
                        </di>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddJobForm_1