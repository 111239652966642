import { FaLaptopCode } from "react-icons/fa";
import { SiReadthedocs } from "react-icons/si";
import { ImHome3 } from "react-icons/im";
import { PiOfficeChairFill } from "react-icons/pi";
import { FaGoogleScholar } from "react-icons/fa6";
import { MdLockClock } from "react-icons/md";
import { BsPersonWalking } from "react-icons/bs";
import { BsBank } from "react-icons/bs";


export const jobTypeSections = [
    {
        icon : <FaLaptopCode />,
        title : "IT Software",
        description : "IT Software",
        color : "#e871b0",
        bgColor : "#e8f5ff"
    },
    {
        icon : <SiReadthedocs />,
        title : "Contingent Job",
        description : "Contingent Job",
        color : "#e27d3a",
        bgColor : "#fce9dd"
    },
    {
        icon : <ImHome3 />,
        title : "Work From Home",
        description : "Work From Home",
        color : "#40aae6",
        bgColor : "#e8f5ff"
    },
    {
        icon : <PiOfficeChairFill />,
        title : "Govt Jobs",
        description : "Govt Jobs",
        color : "#e871b0",
        bgColor : "#e8f5ff"
    },
    {
        icon : <FaGoogleScholar/>,
        title : "Internships",
        description : "Internships  ",
        color : "#8a66ea",
        bgColor : "#e7dffd"
    },
    {
        icon : <MdLockClock />,
        title : "Part Time Jobs",
        description : "Part Time Jobs",
        color : "#e27d3a",
        bgColor : "#fce9dd"
    },
    {
        icon : <BsPersonWalking />,
        title : "Walk-in Jobs",
        description : "Walk-in Jobs",
        color : "#24c9c8",
        bgColor : "#d9ffff",
    },
    {
        icon : <BsBank />,
        title : "Bank Jobs",
        description : "Bank Jobs",
        color : "#e975b3",
        bgColor : "#ffeaf6",
    },
];

export const salaryRange = [
    {
        id : 1,
        range : "5k - 10k"
    },
    {
        id : 2,
        range : "15k - 25k"
    },
    {
        id : 3,
        range : "4 lpa - 6 lpa"
    },
    {
        id : 4,
        range : "8 lpa - 10 lpa"
    },
    {
        id : 5,
        range : "Not disclosed"
    },
];

export const experienceLevel = [
    {
        id : 1,
        title : "1 - 2 years"
    },
    {
        id : 2,
        title : "2 - 3 years"
    },
    {
        id : 3,
        title : "4 - 6 years"
    },
    {
        id : 4,
        title : "7 - 8 years"
    },
    {
        id : 5,
        title : "Fresher"
    },
]
export const jobType = [
    {
        id : 1,
        title : "Work from home"
    },
    {
        id : 2,
        title : "Work from office"
    },
    {
        id : 3,
        title : "Hybrid"
    },
]