import React, { useState } from 'react';
import { MdClose } from 'react-icons/md';
import { useEmployeeContext } from '../../../../context';
import ReactQuill from 'react-quill';

const ExperienceModal = ({ handleModalClose }) => {
    const { addExperienceDetails } = useEmployeeContext();
    const user = JSON.parse(sessionStorage.getItem("data"));
    const [loading, setLoading] = useState(false);
    const [formValues, setFormValues] = useState({
      userId: user._id,
      title: "", // Job title
      description: "", // Job description
      position: "",
      startedAt: "", // Start date
      endedAt: "", // End date
      isActive: false, // Checkbox for currently pursuing
    });
  
    const handleInputChange = (e) => {
      const { name, value, type, checked } = e.target;
      setFormValues({
        ...formValues,
        [name]: type === "checkbox" ? checked : value,
      });
    };
  
    const handleDescriptionChange = (value, delta, source, editor) => {
      setFormValues((prevData) => ({
        ...formValues,
        description: value,
      }));
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
      // Handle form submission
      console.log(formValues);
      addExperienceDetails(formValues);
      setLoading(true);
      setTimeout(() => {
        handleModalClose();
      }, 2000);
    };
  
    return (
      <div className="col-lg-6 bg-white rounded-sm py-3 relative">
        <span
          className="absolute right-4 cursor-pointer"
          onClick={handleModalClose}
          aria-label="Close"
        >
          <MdClose />
        </span>
        <h4 className="font-semibold fs-4 text-[#070f4e]">
          Add Experience Information
        </h4>
        <form onSubmit={handleSubmit} className="py-3">
          <div className="form-group mb-3">
            <label htmlFor="title">Company name <sup className="text-danger">*</sup></label>
            <input
              type="text"
              className="form-input"
              placeholder="Company name"
              name="title"
              value={formValues.title}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group mb-3">
            <label htmlFor="position">Job Title <sup className="text-danger">*</sup></label>
            <input
              type="text"
              className="form-input"
              placeholder="Job Title"
              name="position"
              value={formValues.position}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group mb-3">
            <label htmlFor="description">Job Description <sup className="text-danger">*</sup></label>
            <ReactQuill
              id="description"
              name="description"
              theme="snow"
              modules={{
                toolbar: [
                  ["bold", "italic", "underline"],
                  [{ list: "ordered" }, { list: "bullet" }],
                ],
              }}
              style={{ height: "200px" }}
              onChange={handleDescriptionChange}
            />
          </div>
          <div className="row mt-12">
            <div className="col">
              <div className="form-group mb-3">
                <label htmlFor="startedAt">Started at <sup className="text-danger">*</sup></label>
                <input
                  type="date"
                  className="form-input"
                name="startedAt"
                  value={formValues.startedAt}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>
            <div className="col">
              <div className="form-group mb-3">
                <label htmlFor="endedAt">Ended at <sup className="text-danger">*</sup></label>
                <input
                  type="date"
                  className="form-input"
                  name="endedAt"
                  value={formValues.endedAt}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>
          </div>
          <div className="form-check mb-3">
            <input
              className="form-check-input"
              type="checkbox"
              name="isActive"
              checked={formValues.isActive}
              onChange={handleInputChange}
              id="flexCheckDefault"
            />
            <label className="form-check-label" htmlFor="isActive">
              Currently pursuing
            </label>
          </div>
          <div className="form-group mb-3">
            <button
              type="submit"
              className={`btn-main ${loading ? "bg-gray-400" : "bg-[#272343]"} text-light`}
              disabled={loading}
            >
              {loading ? "Submitting..." : "Submit"}
            </button>
          </div>
        </form>
      </div>
    );
  };
  
  export default ExperienceModal;

