import React from 'react';
import { HashRouter as Router } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import { AdminContextProvider, 
  AuthContextProvider, 
  EmployeeContextProvider, 
  JobContextProvider, 
  SectionContextProvider } from './context';
const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <AuthContextProvider>
      <AdminContextProvider>
        <EmployeeContextProvider>
          <JobContextProvider>
            <SectionContextProvider>
              <Router>
                <App />
              </Router>
            </SectionContextProvider>
          </JobContextProvider>
        </EmployeeContextProvider>
      </AdminContextProvider>
    </AuthContextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
