import React, { useState } from "react";
import ReactQuill from 'react-quill';
import { TagsInput } from 'react-tag-input-component'
import { useSectionContext } from "../../../context";

const AddCategory = () => {
  const {addSectionData} = useSectionContext();
  const userId = JSON.parse(sessionStorage.getItem("data"));
  const [selected, setSelected] = useState([]);
  const [tags, setTags] = useState([])
  const [sectionData, setSectionData] = useState({
    code: "",
    sortId: userId._id,
    title: "",
    basicTitle: "",
    subCategory: [],
    shortDescription: "",
    description: "",
    tags: [],
  });
  const handleInputChange = (e) => {
    setSectionData({
      ...sectionData,
      [e.target.name]: e.target.value,
    });
  };
  const handleQuillChange = (name, value) => {
    setSectionData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }
  const handleShortDescriptionChange = (value, delta, source, editor) => {
    handleQuillChange('shortDescription', value);
  }

  const handleDescriptionChange = (value, delta, source, editor) => {
    handleQuillChange('description', value);
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(sectionData)
    addSectionData(sectionData);
  };
  const clearInput = () => {
    setSectionData((prevData) => ({
      ...prevData,
      code: "",
      title: "",
      basicTitle: "",
      subCategory: [],
      shortDescription: "",
      description: "",
      tags: [],
    }));
    setSelected([]);
    setTags([]);
  }
  return (
    <div className="container-fluid">
      <div className="container">
        <div className="row py-3">
          <h4 className="fs-4">Add Category</h4>
          <div className="col-lg-10 py-3">
            <form>
              <div className="row items-start justify-start mb-3">
                <div className="col-lg-3">
                  <label htmlFor="code" className="text-[14px]">
                    Display icon code <sup className="text-red-600">*</sup>
                  </label>
                </div>
                <div className="col-lg">
                  <input
                    type="text"
                    className="form-input text-[14px] rounded-sm"
                    placeholder="enter icons code from react-icons"
                    name="code"
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="row items-start justify-start mb-3">
                <div className="col-lg-3">
                  <label htmlFor="title" className="text-[14px]">
                    Title <sup className="text-red-600">*</sup>
                  </label>
                </div>
                <div className="col-lg">
                  <input
                    type="text"
                    className="form-input text-[14px] rounded-sm"
                    placeholder="enter title"
                    name="title"
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="row items-start justify-start mb-3">
                <div className="col-lg-3">
                  <label htmlFor="basicTitle" className="text-[14px]">
                    Basic title <sup className="text-red-600">*</sup>
                  </label>
                </div>
                <div className="col-lg">
                  <input
                    type="text"
                    className="form-input text-[14px] rounded-sm"
                    placeholder="enter basic title"
                    name="basicTitle"
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="row items-start justify-start mb-3">
                <div className="col-lg-3">
                  <label htmlFor="tag" className="text-[14px]">
                    Sub category <sup className="text-red-600">*</sup>
                  </label>
                </div>
                <div className="col-lg">
                <TagsInput
                    value={selected}
                    onChange={(newSelected) => {
                        setSelected(newSelected);
                        setSectionData((prevData) => ({
                          ...prevData,
                          subCategory: newSelected, // update subCategory array
                        }));
                      }}
                    name="sub category"
                    placeHolder="press enter to add tags"
                  />
                </div>
              </div>
              <div className="row items-start justify-start mb-3">
                <div className="col-lg-3">
                  <label htmlFor="tag" className="text-[14px]">
                    Tag <sup className="text-red-600">*</sup>
                  </label>
                </div>
                <div className="col-lg">
                <TagsInput
                    value={tags}
                    onChange={(newSelected) => {
                        setTags(newSelected);
                        setSectionData((prevData) => ({
                          ...prevData,
                          tags: newSelected, // update tags array
                        }));
                      }}
                    name="subCategory"
                    placeHolder="press enter to add tags"
                  />
                </div>
              </div>
              <div className="row items-start justify-start mb-14">
                <div className="col-lg-3">
                  <label htmlFor="description" className="text-[14px]">
                    Basic description <sup className="text-red-600">*</sup>
                  </label>
                </div>
                <div className="col-lg relative">
                <ReactQuill
                    id="description"
                    name="description"
                    theme="snow"
                    modules={{
                      toolbar: [
                        ['bold', 'italic', 'underline'],
                        [{ list: 'ordered' }, { list: 'bullet' }],
                      ],
                    }}
                    style={{ height: '200px' }}
                    onChange={handleDescriptionChange}
                  />
                </div>
              </div>
              <div className="row items-start justify-start mb-14">
                <div className="col-lg-3">
                  <label htmlFor="shortDescription" className="text-[14px]">
                    Basic short description <sup className="text-red-600">*</sup>
                  </label>
                </div>
                <div className="col-lg relative">
                <ReactQuill
                    id="shortDescription"
                    name="shortDescription"
                    theme="snow"
                    modules={{
                      toolbar: [
                        ['bold', 'italic', 'underline'],
                        [{ list: 'ordered' }, { list: 'bullet' }],
                      ],
                    }}
                    style={{ height: '200px' }}
                    onChange={handleShortDescriptionChange}
                  />
                </div>
              </div>
              <div className="form-group flex items-center gap-3">
                <button onClick={handleSubmit} className="btn-main bg-[#070f4e] text-white">
                  Submit
                </button>
                <button className="btn-main bg-[#2b7f79] text-white">
                  cancel
                </button>
                <button onClick={clearInput} className="btn-main bg-[#f2b63f] text-white">
                  clear
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCategory;
