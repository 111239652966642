import React, { useEffect, useState } from 'react';
import { useAdminContext, useJobContext } from '../context';
import { useParams } from 'react-router-dom';
import { FaLocationDot } from "react-icons/fa6";
import { FaSuitcase } from "react-icons/fa";
import { PiCurrencyInrBold } from "react-icons/pi";
import DOMPurify from "dompurify";


const JobDetailPage = () => {
    const { getAdminsDetailsById, createJobApplication } = useAdminContext();
    const { getJobsById, getTimeDifference } = useJobContext();
    const { id } = useParams();
    const [fetchedJob, setFetchedJob] = useState(null);
    const [loading, setLoading] = useState(true);
    const [companyData, setCompanyData] = useState(null);
    const user = JSON.parse(sessionStorage.getItem('data'))
    const [appliedJobData, setAppliedJobData] = useState({
            sellerId : '',
            userId: user._id,
            userEmail: user.email,
            firstName: user.firstName,
            lastName: user.lastName,
            resume: 'abc resume',
            location: 'India',
            title: '',
            category: '',
            emailVerified: false,
            deviceType : ''
    })
    const handleApplicationSubmit = () => {
        console.log("this is application detail", appliedJobData)
        createJobApplication(appliedJobData);
    }
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getJobsById(id);
                setFetchedJob(response?.data?.items[0]);
                setLoading(false);
                // Fetch employer details once job data is fetched
                const employerId = response?.data?.items[0]?.sellerId;
                if (employerId) {
                    const companyResponse = await getAdminsDetailsById(employerId, "Employer");
                    setCompanyData(companyResponse?.data[0]);
                }
                setAppliedJobData({
                    ...appliedJobData,
                    sellerId: response?.data?.items[0]?.sellerId,
                    title: response?.data?.items[0]?.title,
                    category: response?.data?.items[0]?.category,
                  });
            } catch (error) {
                console.error('Error fetching job:', error);
            }
        };

        fetchData();

        // Cleanup function if needed
        return () => {
            // Cleanup code here if needed
        };
    }, [getJobsById, getAdminsDetailsById, id]);

    // Return JSX based on loading state
    return loading ? (
        <p>Wait for a moment...</p>
    ) : (
        <div className="container-fluid">
            <div className="container-sm">
                <div className="row py-4">
                    <div className="col-lg-8">
                        <div className="col-lg mb-3">
                            <h4 className='text-2xl font-semibold'>{fetchedJob?.title}</h4>
                            <span>{companyData?.companyName}</span>
                        </div>
                        <div className="col-lg shadow-sm p-2 mb-3">
                            <div className="group flex items-center mb-2 gap-3">
                                <span><FaLocationDot /></span>
                                <span>{companyData?.city}</span>
                            </div>
                            <div className="group flex items-center mb-2 gap-3">
                                <span><FaSuitcase /></span>
                                <span>Required {fetchedJob?.experience} of experience</span>
                            </div>
                            <div className="group flex items-center mb-3 gap-3">
                                <span><PiCurrencyInrBold /></span>
                                <span>{fetchedJob?.salaryRange}</span>
                            </div>
                            <div className="group flex items-center mb-2 gap-3">
                                <span className='bg-gray-100 p-1 font-semibold text-[13px] rounded'>Posted {getTimeDifference(fetchedJob?.createdAt)}</span>
                                <span className='bg-blue-100 p-1 font-semibold text-[13px] rounded'>{fetchedJob?.maxValue} Vacancies</span>
                                <span className='bg-blue-100 p-1 font-semibold text-[13px] rounded'>{fetchedJob?.workType}</span>
                                <span className='bg-blue-100 p-1 font-semibold text-[13px] rounded'>{fetchedJob?.jobType}</span>
                            </div>
                            <div className="group flex items-center justify-end mb-2 gap-3">
                                <button onClick={handleApplicationSubmit} className='btn-main bg-[#272343] text-white'>Apply now</button>
                                <button className='btn-main bg-green-600 text-white'>Whatsapp now</button>
                            </div>
                        </div>
                        <div className="col-lg mb-3 shadow-sm p-2">
                            <h4 className="text-2xl font-semibold">Highlights</h4>
                            <div className='py-3'>
                                <span className="text-[14px]" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(fetchedJob?.shortDescription) }} />
                            </div>
                        </div>
                        <div className="col-lg mb-3 shadow-sm p-2">
                            <h4 className="text-2xl font-semibold">Description</h4>
                            <div className='py-3'>
                                <p className="text-[14px]" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(fetchedJob?.description) }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default JobDetailPage;
