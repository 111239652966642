import React from 'react'
import { Route, Routes } from 'react-router-dom'
import SharedLayout from './SharedLayout'
import { Home } from '../pages'
import EmployerLayout from './EmployerLayout'
import RequiresAuth from './RequiresAuth'
import { employeeRoutes, employerRoutes } from './privateRoutes'
import EmployeeLayout from './EmployeeLayout'
import EmployeeHome from '../shared/employeePages/components/EmployeeHome'
import { authRoutes, employeeContentRoutes } from './publicRoutes'

const Index = () => {
  const role = sessionStorage.getItem('role')
  const token = sessionStorage.getItem('token')

  return (
    <Routes>
      {role === 'Employer' && token ? (
        <Route element={<EmployerLayout />}>
          <Route path='*' element={<Home />} />
          <Route element={<RequiresAuth />}>
            {employerRoutes.map((route, idx) => (
              <Route key={idx} path={route.path} element={route.element} />
            ))}
          </Route>
        </Route>
      ) : role === 'Employee' && token ? (
        <Route element={<EmployeeLayout />}>
          <Route path='*' element={<EmployeeHome />} />
          {employeeContentRoutes?.map((emp, idx) => (
            <Route key={idx} path={emp.path} element={emp.element} />
          ))}
          <Route element={<RequiresAuth />}>
            {employeeRoutes?.map((route, idx) => (
              <Route key={idx} path={route.path} element={route.element} />
            ))}
          </Route>
        </Route>
      ) : (
        <Route element={<SharedLayout />}>
          <Route path='*' element={<Home />} />
            {authRoutes?.map((route, idx) => (
              <Route key={idx} path={route.path} element={route.element} />
            ))}
        </Route>
      )}
    </Routes>
  )
}

export { Index }