import React, { createContext, useEffect, useId, useState } from 'react';
import axios from 'axios';
import { addJobUrl, deleteProductById, fetchAllProductById, fetchAllProductByUserId, fetchAllProducts } from '../../api/Api';
import { toast } from "react-toastify";

export const JobContext = createContext();

const JobContextProvider = ({ children }) => {
    const [Jobs, setJobs] = useState();
    const [allJobs, setAllJobs] = useState([])
    const userId = JSON.parse(sessionStorage.getItem('data'))
    const addJobData = async(data) =>  {
      try {
        const response = await axios.post(addJobUrl, data);
        toast.success("job created successfully");
        window.location.reload();
        return response;
      } catch (error) {
        console.log(error)
      }
    }
    
    const getJobAllJobs = async() => {
        try {
            const response = await axios.get(fetchAllProducts);
            setAllJobs(response.data.items)
        } catch (error) {
            console.log(error);
        }
    }

    const getJobAllJobsById = async () => {
      try {
        const response = await axios.get(`${fetchAllProductByUserId}/${userId._id}`);
        setJobs(response.data.items);
      } catch (error) {
        console.log(error);
      }
    };

    const getJobsById = async (id) => {
      try {
        const response = await axios.get(`${fetchAllProductById}/${id}`);
        return response;
      } catch (error) {
        console.log(error);
      }
    };

    const getTimeDifference = (postedOn) => {
      const currentDate = new Date();
      const postedDate = new Date(postedOn);
      const diffInMilliseconds = currentDate - postedDate;
      const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
      const diffInMinutes = Math.floor(diffInSeconds / 60);
      const diffInHours = Math.floor(diffInMinutes / 60);
      const diffInDays = Math.floor(diffInHours / 24);
      const diffInWeeks = Math.floor(diffInDays / 7);
      const diffInMonths = Math.floor(diffInDays / 30);
    
      if (diffInMonths > 0) {
        return `${diffInMonths} month${diffInMonths > 1 ? 's' : ''} ago`;
      } else if (diffInWeeks > 0) {
        return `${diffInWeeks} week${diffInWeeks > 1 ? 's' : ''} ago`;
      } else if (diffInDays > 0) {
        return `${diffInDays} day${diffInDays > 1 ? 's' : ''} ago`;
      } else if (diffInHours > 0) {
        return `${diffInHours} hour${diffInHours > 1 ? 's' : ''} ago`;
      } else if (diffInMinutes > 0) {
        return `${diffInMinutes} minute${diffInMinutes > 1 ? 's' : ''} ago`;
      } else {
        return 'Just now';
      }
    };
    const deleteJobById = async(id) => {
      try {
        const response = await axios.delete(`${deleteProductById}/${id}`);
        toast.success("job deleted successfully")
        window.location.reload();
        return response;
      } catch (error) {
        console.log(error);
      }
    }

    useEffect(() => {
        getJobAllJobs();
        getJobAllJobsById();
    },[])
  return (
    <JobContext.Provider value={{Jobs, addJobData, getTimeDifference, deleteJobById,getJobsById, allJobs }}>
      {children}
    </JobContext.Provider>
  )
}

export default JobContextProvider;