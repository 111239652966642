import React, { useState } from 'react'
import { confirmAlert } from 'react-confirm-alert';
import { Link } from 'react-router-dom'
import { useAuthContext } from '../../context';
import { BsList } from "react-icons/bs";
import { IoIosClose } from "react-icons/io";



const EmployeeHeader = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const {logoutUser} = useAuthContext();

  const handleSidebarLinkClick = () => {
    setIsSidebarOpen(true); // Close sidebar after clicking any link
  };
  const Logout = () => {
    confirmAlert({
      title: 'Confirm to logout',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => logoutUser()
        },
        {
          label: 'No',
          onClick: () => ({})
        }
      ]
    });
  }
  return (
    <>
      <div className="bg-[#272343] overflow-hidden">
          <div className="row py-2 items-center">
            <div className="col-lg-3 text-center">
              <Link to='/' className="font-bold text-xl text-white">RESO</Link>
            </div>
            <div className="col-lg">
              <div>
                <form className='flex search-input-box rounded-pill'>
                  <input type="text" className='search-input' placeholder='type keywords, title, location' />
                  <button className='btn-main bg-[#bae8e8] text-[#272343] rounded-pill'>Search</button>
                </form>
              </div>
            </div>
            <div className="col-lg-4">
              <ul className="flex items-center gap-3">
                <li>
                  <Link to='' className="text-[13px] text-white">Invite friends</Link>
                </li>
                <li>
                  <Link to='/view-jobs' className="text-[13px] text-white">Jobs</Link>
                </li>
                <li>
                  <Link to='/view-my-applications' className="text-[13px] text-white">My Applications</Link>
                </li>
                <li>
                  <Link onClick={() => setIsSidebarOpen(false)} className="text-white flex items-center gap-2"><BsList size={20} /><img src="https://avatar.iran.liara.run/public" alt="" className='size-10' /></Link>
                </li>
              </ul>
            </div>
          </div>
          <div className={isSidebarOpen ? 'sidebar h-[100vh] bg-[#272343] w-[20%] absolute top-0 right-0 translate-x-full transition-all' : 'sidebar h-[100vh] bg-[#272343] w-[20%] absolute top-0 right-0 translate-x-0 transition-all'}>
              <div className="col flex flex-col items-center justify-center relative">
                <span onClick={() => setIsSidebarOpen(true)} className='absolute right-4  top-3 cursor-pointer'><IoIosClose size={35} /></span>
                <div className="col-lg flex flex-col items-center justify-center gap-3 bg-light w-100 py-2">
                  <Link className="text-white flex items-center gap-2"><img src="https://avatar.iran.liara.run/public" alt="" className='size-24 img-thumbnail rounded-circle' /></Link>
                  <button className='btn-main bg-[#bae8e8]'>Update profile</button>
                </div>
                <ul className='nav flex-column items-center w-100'>
                  <li className='w-100 hover:bg-[#46a3a3] py-3 text-center'>
                    <Link className='text-center text-[13px] text-light'>Notification</Link>
                  </li>
                  <li className='w-100 hover:bg-[#46a3a3] py-3 text-center'>
                    <Link to='/view-profile' onClick={handleSidebarLinkClick} className='text-center text-[13px] text-light'>My Account</Link>
                  </li>
                  <li onClick={Logout} className='w-100 hover:bg-[#46a3a3] py-3 text-center'>
                    <Link className='text-center text-[13px] text-light'>Log out</Link>
                  </li>
                </ul>
              </div>
          </div>
      </div>
    </>
  )
}

export default EmployeeHeader