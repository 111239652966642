import React from 'react'
import { EmployeeHeader } from '../components'
import { Outlet } from 'react-router-dom'

const EmployeeLayout = () => {
  return (
    <>
        <EmployeeHeader />
        <Outlet />
    </>
  )
}

export default EmployeeLayout