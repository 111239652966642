import React, { useEffect, useState } from 'react';
import { useAdminContext, useEmployeeContext } from '../../../context';

const EmployeeApplied = () => {
  const { getApplicationByUserId, getAdminsDetailsById } = useAdminContext();
  const {formatDate} = useEmployeeContext();
  const [applications, setApplications] = useState([]);
  const [companyData, setCompanyData] = useState([]);

  useEffect(() => {
    const getUserApplications = async () => {
      try {
        const response = await getApplicationByUserId();
        if (response && response.data) {
          setApplications(response.data);

          // Extract sellerIds from applications
          const sellerIds = response.data.map(application => application.sellerId);

          // Fetch details for each sellerId
          const adminDetailsPromises = sellerIds.map(sellerId => 
            getAdminsDetailsById(sellerId, "Employer")
          );

          // Resolve all promises
          const adminDetailsResponses = await Promise.all(adminDetailsPromises);

          // Log and set company data
          setCompanyData(adminDetailsResponses.map(response => response.data[0]));
        }
      } catch (error) {
        console.error("Error fetching applications:", error);
      }
    };

    getUserApplications();
  }, [getApplicationByUserId, getAdminsDetailsById]);

  return (
    <>
      <div className="container-fluid">
        <div className="container py-3">
          <div className="row">
            <h3 className='text-2xl font-semibold text-[#070f4e]'>My Applications ({applications?.length})</h3>
          </div>
          <div className="row">
            <table className="table text-[14px]">
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Company name</th>
                  <th>Location</th>
                  {/* <th>Category</th> */}
                  <th>Applied Date</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {applications.map((application, index) => (
                  <tr key={index}>
                    <td>{application.title}</td>
                    <td>
                      {companyData[index]?.companyName || "Fetching..."}
                    </td>
                    <td>
                      {companyData[index]?.city || "Fetching..."}
                    </td>
                    {/* <td>{application.category}</td> */}
                    <td>{formatDate(application.createdAt)}</td>
                    <td>
                      <span className='bg-success text-light p-1 rounded text-[14px]'>
                        {application.status}
                      </span>
                    </td>
                  </tr>
                 ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployeeApplied;
